import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BlogStoreInterface } from "../../../interfaces/BlogInterface";
import { Table, SelectColumnFilter } from "../../../utils/Table/Table";
import {
	BsCameraVideoFill,
	BsCamera2,
	BsFilePdfFill,
	BsPersonFill,
	BsSuitHeart,
	BsChatQuote,
	BsXCircleFill,
	BsCheckCircleFill
} from "react-icons/bs";
import { RemovePostModal } from "./RemovePostModal";
import { SeniorInterface } from "../../../interfaces/UserStateInterface";
import WhoLikeModal from "../WhoLikeModal";
import { handleEditRemoveButtons } from "../BlogUtils";

interface PostsListProps {
	blogStore: BlogStoreInterface;
	blogDispatch: Function;
	isBlogger: boolean;
	canRemovePost: boolean;
	isModerator: boolean;
	seniors: SeniorInterface[];
	userId: number;
	newsValidation: Function;
}

export default function PostsList({
	blogStore,
	blogDispatch,
	isBlogger,
	canRemovePost,
	isModerator,
	seniors,
	userId,
	newsValidation
}: PostsListProps) {
	const { t } = useTranslation("common");
	const [showRemoveConfirmation, setShowRemoveConfirmation] = useState({
		postId: null,
		postTitle: null
	});
	const [showLikes, setShowLikes] = useState({ show: false, postId: null });
	const posts = blogStore.news;

	const columns = [
		{
			Header: t("common.__status__"),
			accessor: "published",
			Filter: SelectColumnFilter,
			minWidth: 30,
			width: 35,
			maxWidth: 40,
			Cell: (row: any) => (
				<>
					{row.row.original.accepted === true && (
						<span className="badge badge-pill badge-primary">{t("news.__moderationOk__")}</span>
					)}
					{row.row.original.accepted === false && (
						<span className="badge badge-pill badge-danger">{t("news.__moderationRefused__")}</span>
					)}
					{row.row.original.accepted === null && (
						<span className="badge badge-pill badge-secondary">{t("news.__moderationPending__")}</span>
					)}
				</>
			)
		},
		{
			Header: t("news.__title__"),
			accessor: "title",
			Cell: (row: any) => (
				<>
					<span className="meta">{row.row.original.eventDate}</span>
					<br />
					<span className="post-list-title">
						<Link
							to={{
								pathname: `/post/${row.row.original.id}`,
								state: { post: row.row.original }
							}}>
							{row.row.values.title}
						</Link>
					</span>
					<div className="post-list-meta">
						<BsCamera2 className="kpi-icon grey mr-2" />
						<span className="mr-2">{row.row.original.photoCounter}</span>
						<BsFilePdfFill className="kpi-icon grey mr-2" />
						<span className="mr-2">{row.row.original.pdfCounter}</span>
						<BsCameraVideoFill className="kpi-icon grey mr-2" />
						<span className="mr-2">{row.row.original.videosCounter}</span>
						<BsPersonFill className="kpi-icon grey mr-2" />
						{row.row.original.focused_on ? row.row.original.seniors_focused_on.length : 0}
					</div>
				</>
			)
		},
		{
			Header: "",
			accessor: "like_counter",
			disableFilters: true,
			minWidth: 100,
			width: 110,
			maxWidth: 120,
			Cell: (row: any) =>
				row.row.original.accepted ? (
					<div className="post-list-meta">
						<BsSuitHeart
							role="button"
							onClick={() => setShowLikes({ show: true, postId: row.row.original.id })}
							className="big-kpi-icon red mr-1"
						/>{" "}
						{row.row.values.like_counter}
						<BsChatQuote className="big-kpi-icon blue mr-1 ml-2" /> {row.row.original.comment_counter}
					</div>
				) : null
		},
		{
			Header: "",
			accessor: "id",
			disableFilters: true,
			minWidth: 50,
			width: 75,
			maxWidth: 100,
			Cell: (row: any) => (
				<div className="right">
					{row.row.original.accepted === null && isModerator ? (
						<BsXCircleFill
							className="big-kpi-icon red"
							role="button"
							onClick={() => newsValidation(row.row.original.id, false)}
						/>
					) : null}
					{row.row.original.accepted === null && isModerator ? (
						<BsCheckCircleFill
							className="big-kpi-icon blue"
							role="button"
							onClick={() => newsValidation(row.row.original.id, true)}
						/>
					) : null}
					{handleEditRemoveButtons(
						row.row.original.accepted,
						row.row.original.added_by,
						row.row.original.id,
						row.row.original.title,
						isBlogger,
						canRemovePost,
						userId,
						isModerator,
						setShowRemoveConfirmation
					)}
				</div>
			)
		}
	];

	return (
		<>
			{posts.length > 0 ? (
				<Table columns={columns} data={posts} skipReset={true} />
			) : (
				<p className="meta">No posts yet</p>
			)}
			<RemovePostModal
				show={showRemoveConfirmation}
				setShow={setShowRemoveConfirmation}
				blogDispatch={blogDispatch}
				redirection={false}
			/>
			<WhoLikeModal show={showLikes} setShow={setShowLikes} seniors={seniors} />
		</>
	);
}
