import React, { useContext, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BlogContext } from "../context/BlogContext";
import PostsList from "../components/Blog/PostsManagement/PostsList";
import { UserInterface, SeniorInterface } from "../interfaces/UserStateInterface";
import * as p from "../constants/Permissions";
import * as url from "../constants/Url";
import http from "../services/interceptor";
import { postState } from "../utils/Blogging";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import BlogHeaderNav from "../layout/BlogHeaderNav";

interface PostManagementProps {
	userState: UserInterface;
}

export default function PostsManagement({ userState }: PostManagementProps) {
	const { t } = useTranslation("common");
	const seniors = userState.seniors;
	const homeId = userState.userConnected.home_id;
	const { blogStore, blogDispatch } = useContext(BlogContext);
	const isBlogger =
		userState.userConnected.is(p.HOME_BLOG_WRITER) &&
		!userState.userConnected.is_in([p.CAN_MODERATE_HOME_BLOG, p.CAN_POST_MODIFY_HOME_BLOG]);

	interface mediaInterface {
		content_length: number;
		content_type: string;
		filename: string | null;
		path: string;
		path_original: string;
	}

	const newsValidation = useCallback((id: number, boolean: boolean) => {
		const data = {
			accepted: boolean
		};
		http.put(`${url.HOMES_URL}/blog_posts/${id}/moderate`, data).then(res => {
			blogDispatch({
				type: "EDIT_POST",
				payload: { post: res.data.blog_post, locale: userState.userConnected.locale }
			});
		});
	}, []);

	useEffect(() => {
		blogDispatch({ type: "LOADING" });
		if (homeId && blogStore.news.length <= 1) {
			const blogUrl = isBlogger ? `${url.ME_URL}/blog_posts` : `${url.HOMES_URL}/${homeId}/blog_posts`;
			http.get(blogUrl).then(res => {
				const result = res.data.blog_posts;
				const dataToDisplay = result.map((post: any) => {
					return {
						...post,
						published: postState(post.accepted),
						eventDate: handleDate(post.date, "P", userState.userConnected.locale),
						videosCounter: post.medias.filter(
							(media: mediaInterface) => media.content_type.split("/")[0] === "video"
						).length,
						pdfCounter: post.medias.filter(
							(media: mediaInterface) => media.content_type === "application/pdf"
						).length,
						photoCounter: post.medias.filter(
							(media: mediaInterface) => media.content_type.split("/")[0] === "image"
						).length
					};
				});
				blogDispatch({
					type: "GET_POSTS",
					payload: dataToDisplay
				});
			});
		}
		// eslint-disable-next-line
	}, [homeId]);

	return (
		<>
			<BlogHeaderNav title={t("news.__handleNews__")} />
			<div className="content-layout">
				<PostsList
					blogStore={blogStore}
					blogDispatch={blogDispatch}
					isBlogger={isBlogger}
					canRemovePost={userState.userConnected.is(p.CAN_POST_MODIFY_HOME_BLOG)}
					isModerator={userState.userConnected.is(p.CAN_MODERATE_HOME_BLOG)}
					seniors={seniors}
					userId={userState.userConnected.id}
					newsValidation={newsValidation}
				/>
			</div>
		</>
	);
}
