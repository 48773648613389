import i18n from "../i18n/i18next";

const OTHER_PHYSICAL = 801;
const OTHER_INTELLECTUAL = 802;
const OTHER_CULTURAL = 803;
const OTHER_MANUAL = 804;
const OTHER_SOCIAL = 805;
const OTHER_WELL_BEING = 806;
const OTHER_MISCELLANEOUS = 807;

const HAIRDRESSER = 300;
const BEAUTICIAN = 301;
const BEAUTICIAN_MANICURE = 302;
const PET_THERAPY = 303;
const TAPPING_MASSAGE = 305;
const SNOEZELEN = 350;

const SOFT_GYM = 901;
const WORKSHOP_BALANCE = 902;
const WORKSHOP_MEMORY = 903;
const CHAT = 904;
const QUIZZ = 905;
const VISIT = 910;
const SLIDESHOW = 911;
const SHOW = 912;
const THEATER = 913;
const PETANQUE = 914;
const PROMENADE = 915;
const READING = 916;
const DIGITAL_GAMING = 917;
const PETIT_BAC = 918;
const BINGO = 919;
const MODELING = 920;
const GARDENING = 921;
const COOKING = 922;
const DIY = 923;
const MUSIC_SONG = 924;
const MOVIE_THEATER = 925;
const SEWING_KNITTING = 926;
const CREATIVE_WORKSHOP = 927;

const INTERGENERATIONAL_MEETING = 930;
const CORRESPONDENCE = 931;
const THEMED_MEAL = 932;
const THEMED_EVENT = 933;
const ANIMATED_SNACK = 934;
const WELLNESS_WORKSHOP = 935;
const WORDS_NUMBERS_GAMES = 936;
const WORSHIP = 937;

export function getActivityAction(aid: any) {
	switch (aid) {
		case HAIRDRESSER:
			return i18n.t("activities:activities.hairDresser");
		case BEAUTICIAN:
			return i18n.t("activities:activities.beautician");
		case BEAUTICIAN_MANICURE:
			return i18n.t("activities:activities.beauticianManicure");
		case TAPPING_MASSAGE:
			return i18n.t("activities:activities.tappingMassage");
		case PET_THERAPY:
			return i18n.t("activities:activities.petTherapy");
		case SNOEZELEN:
			return i18n.t("activities:activities.snoezelen");
		case SOFT_GYM:
			return i18n.t("activities:activities.softGym");
		case WORKSHOP_BALANCE:
			return i18n.t("activities:activities.workshopBalance");
		case WORKSHOP_MEMORY:
			return i18n.t("activities:activities.workshopMemory");
		case CHAT:
			return i18n.t("activities:activities.chat");
		case QUIZZ:
			return i18n.t("activities:activities.quizz");
		case VISIT:
			return i18n.t("activities:activities.visit");
		case SLIDESHOW:
			return i18n.t("activities:activities.slideshow");
		case SHOW:
			return i18n.t("activities:activities.show");
		case THEATER:
			return i18n.t("activities:activities.theater");
		case MODELING:
			return i18n.t("activities:activities.modeling");
		case GARDENING:
			return i18n.t("activities:activities.gardening");
		case COOKING:
			return i18n.t("activities:activities.cooking");
		case DIY:
			return i18n.t("activities:activities.diy");
		case INTERGENERATIONAL_MEETING:
			return i18n.t("activities:activities.intergenerationalMeeting");
		case CORRESPONDENCE:
			return i18n.t("activities:activities.correspondence");
		case PETANQUE:
			return i18n.t("activities:activities.petanque");
		case PROMENADE:
			return i18n.t("activities:activities.promenade");
		case READING:
			return i18n.t("activities:activities.reading");
		case DIGITAL_GAMING:
			return i18n.t("activities:activities.digitalGaming");
		case PETIT_BAC:
			return i18n.t("activities:activities.petitBac");
		case BINGO:
			return i18n.t("activities:activities.bingo");
		case MUSIC_SONG:
			return i18n.t("activities:activities.musicSong");
		case MOVIE_THEATER:
			return i18n.t("activities:activities.cinema");
		case SEWING_KNITTING:
			return i18n.t("activities:activities.sewing");
		case CREATIVE_WORKSHOP:
			return i18n.t("activities:activities.creativeWorkshop");
		case THEMED_EVENT:
			return i18n.t("activities:activities.themedEvent");
		case THEMED_MEAL:
			return i18n.t("activities:activities.themedMeal");
		case ANIMATED_SNACK:
			return i18n.t("activities:activities.animatedSnack");
		case WELLNESS_WORKSHOP:
			return i18n.t("activities:activities.wellnessWorkshop");
		case WORDS_NUMBERS_GAMES:
			return i18n.t("activities:activities.wordsNumbersGames");
		case WORSHIP:
			return i18n.t("activities:activities.worship");
		case OTHER_PHYSICAL:
			return i18n.t("activities:activities.physicalActivity");
		case OTHER_INTELLECTUAL:
			return i18n.t("activities:activities.intellectualActivity");
		case OTHER_CULTURAL:
			return i18n.t("activities:activities.culturalActivity");
		case OTHER_MANUAL:
			return i18n.t("activities:activities.manualActivity");
		case OTHER_SOCIAL:
			return i18n.t("activities:activities.socialActivity");
		case OTHER_WELL_BEING:
			return i18n.t("activities:activities.wellBeingActivity");
		case OTHER_MISCELLANEOUS:
			return i18n.t("activities:activities.otherActivity");
		default:
			return i18n.t("activities:activities.visit");
	}
}

const physicalActivities = [
	{
		value: 901,
		label: i18n.t("activities:activities.softGym")
	},
	{
		value: 902,
		label: i18n.t("activities:activities.workshopBalance")
	},
	{
		value: 914,
		label: i18n.t("activities:activities.petanque")
	},
	{
		value: 915,
		label: i18n.t("activities:activities.promenade")
	},
	{
		value: 801,
		label: `${i18n.t("activities:activities.other")} ${i18n.t(
			"activities:activities.physicalActivity"
		)}`
	}
];

const culturalActivities = [
	{
		value: 910,
		label: i18n.t("activities:activities.visit")
	},
	{
		value: 911,
		label: i18n.t("activities:activities.slideshow")
	},
	{
		value: 912,
		label: i18n.t("activities:activities.show")
	},
	{
		value: 913,
		label: i18n.t("activities:activities.theater")
	},
	{
		value: 924,
		label: i18n.t("activities:activities.musicSong")
	},
	{
		value: 925,
		label: i18n.t("activities:activities.cinema")
	},
	{
		value: 803,
		label: `${i18n.t("activities:activities.other")} ${i18n.t(
			"activities:activities.culturalActivity"
		)}`
	}
];

const socialActivities = [
	{
		value: 930,
		label: i18n.t("activities:activities.intergenerationalMeeting")
	},
	{
		value: 931,
		label: i18n.t("activities:activities.correspondence")
	},
	{
		value: 932,
		label: i18n.t("activities:activities.themedMeal")
	},
	{
		value: 933,
		label: i18n.t("activities:activities.themedEvent")
	},
	{
		value: 934,
		label: i18n.t("activities:activities.animatedSnack")
	},
	{
		value: 805,
		label: `${i18n.t("activities:activities.other")} ${i18n.t(
			"activities:activities.socialActivity"
		)}`
	}
];

const intellectualActivity = [
	{
		value: 903,
		label: i18n.t("activities:activities.workshopMemory")
	},
	{
		value: 905,
		label: i18n.t("activities:activities.quizz")
	},
	{
		value: 917,
		label: i18n.t("activities:activities.digitalGaming")
	},
	{
		value: 919,
		label: i18n.t("activities:activities.bingo")
	},
	{
		value: 904,
		label: i18n.t("activities:activities.chat")
	},
	{
		value: 916,
		label: i18n.t("activities:activities.reading")
	},
	{
		value: 918,
		label: i18n.t("activities:activities.petitBac")
	},
	{
		value: 936,
		label: i18n.t("activities:activities.wordsNumbersGames")
	},
	{
		value: 802,
		label: `${i18n.t("activities:activities.other")} ${i18n.t(
			"activities:activities.intellectualActivity"
		)}`
	}
];

const manualActivities = [
	{
		value: 920,
		label: i18n.t("activities:activities.modeling")
	},
	{
		value: 921,
		label: i18n.t("activities:activities.gardening")
	},
	{
		value: 922,
		label: i18n.t("activities:activities.cooking")
	},
	{
		value: 923,
		label: i18n.t("activities:activities.diy")
	},
	{
		value: 926,
		label: i18n.t("activities:activities.sewing")
	},
	{
		value: 927,
		label: i18n.t("activities:activities.creativeWorkshop")
	},
	{
		value: 804,
		label: `${i18n.t("activities:activities.other")} ${i18n.t(
			"activities:activities.manualActivity"
		)}`
	}
];

const wellBeingActivities = [
	{
		value: 350,
		label: i18n.t("activities:activities.snoezelen")
	},
	{
		value: 303,
		label: i18n.t("activities:activities.petTherapy")
	},
	{
		value: 300,
		label: i18n.t("activities:activities.hairDresser")
	},
	{
		value: 301,
		label: i18n.t("activities:activities.beautician")
	},
	{
		value: 302,
		label: i18n.t("activities:activities.beauticianManicure")
	},
	{
		value: 935,
		label: i18n.t("activities:activities.wellnessWorkshop")
	},
	{
		value: 305,
		label: i18n.t("activities:activities.tappingMassage")
	},
	{
		value: 806,
		label: `${i18n.t("activities:activities.other")} ${i18n.t(
			"activities:activities.wellBeingActivity"
		)}`
	}
];

export const activityToSelect = [
	{
		label: i18n.t("activities:activities.worship"),
		value: 937
	},
	{
		label: i18n.t("activities:activities.physicalActivity"),
		options: physicalActivities
	},
	{
		label: i18n.t("activities:activities.culturalActivity"),
		options: culturalActivities
	},
	{
		label: i18n.t("activities:activities.socialActivity"),
		options: socialActivities
	},
	{
		label: i18n.t("activities:activities.intellectualActivity"),
		options: intellectualActivity
	},
	{
		label: i18n.t("activities:activities.manualActivity"),
		options: manualActivities
	},
	{
		label: i18n.t("activities:activities.wellBeingActivity"),
		options: wellBeingActivities
	},
	{
		label: i18n.t("activities:activities.otherActivity"),
		value: 807
	}
];
