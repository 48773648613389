import React, { createContext, useReducer } from "react";
import BlogReducer from "./BlogReducer";

const initialState = {
	loading: false,
	news: [],
	post: {},
	error: false,
	success: false,
	msg: null
};

export const BlogContext = createContext();

export const BlogContextProvider = ({ children }) => {
	const [blogStore, blogDispatch] = useReducer(BlogReducer, initialState);

	return (
		<BlogContext.Provider
			value={{
				blogStore,
				blogDispatch
			}}>
			{children}
		</BlogContext.Provider>
	);
};

export const BlogContextConsumer = BlogContext.Consumer;
