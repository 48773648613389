import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SubmitHandler, useFormContext } from "react-hook-form";
import MyInputText from "../../utils/Form/MyInputText";
import OnlyDatePicker from "../../utils/DatePicker/OnlyDatePicker";
import OnlyTimePicker from "../../utils/DatePicker/OnlyTimePicker";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import http from "../../services/interceptor";
import * as url from "../../constants/Url";
import HandleError from "../../layout/HandleError";
import * as types from "../../constants/CalendarEntries";

interface AddEventFormProps {
	selectedDate: Date;
	setSelectedDate: Function;
	userLocale: string;
	homeId: number | null;
}

type FormValues = {
	addevent_title: string;
	addevent_details: string;
};

export default function AddEventForm({
	selectedDate,
	userLocale,
	homeId,
	setSelectedDate
}: AddEventFormProps) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const { handleSubmit, reset } = useFormContext<FormValues>();
	const [eventType, setEventType] = useState(types.WORKSHOP);
	const eventDate = new Date(selectedDate);
	const [startDate, setStartDate] = useState(eventDate);
	const [startTime, setStartTime] = useState(eventDate);
	const [endDate, setEndDate] = useState(eventDate);
	const [endTime, setEndTime] = useState(eventDate);
	const [error, setError] = useState(false);
	const [daysSelectionError, setDaysSelectionError] = useState(false);
	const [recurring, setRecurring] = useState(false);
	const [recurringType, setRecurringType] = useState(types.SINGLE);
	const [selectedDays, setSelectedDays] = useState<number[] | []>([]);

	const handleDays = (value: number) => {
		if (selectedDays.length > 0 && selectedDays.find(day => day === value)) {
			const days = selectedDays.filter(day => day !== value);
			setSelectedDays(days);
		} else {
			setSelectedDays([...selectedDays, value]);
		}
	};

	const onSubmit: SubmitHandler<FormValues> = values => {
		if (startDate && homeId) {
			let data = null;
			const startDateSelected = handleDate(startDate, "yyyy-MM-dd");
			const startTimeSelected = handleDate(startTime, "HH:mm:ss");
			const endDateSelected = endDate ? handleDate(endDate, "yyyy-MM-dd") : null;
			const endTimeSelected = endTime ? handleDate(endTime, "HH:mm:ss") : null;
			setError(false);
			if (recurring) {
				if (recurringType === types.SELECTED_DAYS && selectedDays.length === 0) {
					setDaysSelectionError(true);
				} else {
					setDaysSelectionError(false);
					data = {
						home_calendar_entry: {
							begin_date: startDateSelected,
							begin_time: startTimeSelected,
							end_date: endDateSelected,
							end_time: endTimeSelected,
							detail: values.addevent_details,
							entry_type_id: eventType,
							home_id: homeId,
							info: values.addevent_title,
							recurring: true,
							recurring_type: 1,
							days_of_week: recurringType === types.EVERY_WORKING_DAYS ? [1, 2, 3, 4, 5] : selectedDays
						}
					};
				}
			} else {
				const start = startDateSelected + " " + startTimeSelected;
				const end = endDateSelected && endTimeSelected ? endDateSelected + " " + endTimeSelected : null;
				data = {
					home_calendar_entry: {
						begin_datetime: start,
						end_datetime: end,
						detail: values.addevent_details,
						entry_type_id: eventType,
						home_id: homeId,
						info: values.addevent_title,
						recurring: false
					}
				};
			}
			if (data) {
				http
					.post(`${url.BASE_URL}/home_calendar_entries`, data)
					.then(res => {
						reset({ addevent_title: "", addevent_details: "" });
						const eventDate = res.data.calendar_entry.begin_datetime;
						setSelectedDate(new Date(eventDate));
						history.push({ pathname: `/calenday`, state: { success: true } });
					})
					.catch(() => setError(true));
			}
		} else {
			setError(true);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{error ? (
				<HandleError message={t("common.__errorOccured__")} type="error" setError={setError} />
			) : null}
			<div className="btn-group" role="group" aria-label="Select Event Type">
				<button
					type="button"
					onClick={() => setEventType(types.WORKSHOP)}
					className={eventType === types.WORKSHOP ? `btn btn-primary` : `btn btn-outline-primary`}>
					{t("calendar.__workshop__")}
				</button>
				<button
					type="button"
					onClick={() => setEventType(types.EVENT)}
					className={eventType === types.EVENT ? `btn btn-primary` : `btn btn-outline-primary`}>
					{t("calendar.__event__")}
				</button>
			</div>
			<MyInputText
				name="addevent_title"
				label={t("calendar.__eventName__")}
				initialValue=""
				behavior={{ required: true }}
			/>
			<MyInputText
				name="addevent_details"
				label={t("calendar.__eventDetails__")}
				initialValue=""
				behavior={{ required: false }}
			/>

			<div className="btn-group mt-3" role="group" aria-label="Select Recurring Type">
				<button
					type="button"
					onClick={() => {
						setRecurring(true);
						setRecurringType(types.EVERY_WORKING_DAYS);
					}}
					className={
						recurringType === types.EVERY_WORKING_DAYS ? `btn btn-primary` : `btn btn-outline-primary`
					}>
					{t("calendar.__everyWeekDays__")}
				</button>
				<button
					type="button"
					onClick={() => {
						setRecurring(true);
						setRecurringType(types.SELECTED_DAYS);
					}}
					className={
						recurringType === types.SELECTED_DAYS ? `btn btn-primary` : `btn btn-outline-primary`
					}>
					{t("calendar.__everySelectedDays__")}
				</button>
				<button
					type="button"
					onClick={() => {
						setRecurring(false);
						setRecurringType(types.SINGLE);
					}}
					className={recurringType === types.SINGLE ? `btn btn-primary` : `btn btn-outline-primary`}>
					{t("calendar.__noRecurring__")}
				</button>
			</div>

			<div className="header-details mt-4">
				{recurring ? (
					recurringType === types.EVERY_WORKING_DAYS ? (
						<div className="row">
							<div className="col-12 mt-2">
								<label>{t("calendar.__firstAppointmentDate__")}</label>
							</div>
							<div className="col-6">
								<OnlyDatePicker startDate={startDate} setStartDate={setStartDate} userLocale={userLocale} />
							</div>

							<div className="col-6">
								<OnlyTimePicker startTime={startTime} setStartTime={setStartTime} userLocale={userLocale} />
							</div>

							<div className="col-12 mt-4">
								<label>{t("calendar.__lastAppointmentDate__")}</label>
							</div>
							<div className="col-6">
								<OnlyDatePicker startDate={endDate} setStartDate={setEndDate} userLocale={userLocale} />
							</div>
							<div className="col-6">
								<OnlyTimePicker startTime={endTime} setStartTime={setEndTime} userLocale={userLocale} />
							</div>
						</div>
					) : recurringType === types.SELECTED_DAYS ? (
						<div className="row">
							<div className="col-12">
								{daysSelectionError ? (
									<HandleError
										message={t("calendar.__selectedDaysError__")}
										type="error"
										setError={setDaysSelectionError}
									/>
								) : null}
								<div className="d-flex flex-wrap mt-2">
									{types.selectedDays.map(day => (
										<p key={day.id} className="mr-4">
											<input type="checkbox" key={day.id} onClick={() => handleDays(day.id)} /> {day.name}
										</p>
									))}
								</div>
							</div>
							<div className="col-12 mt-2">
								<label>{t("calendar.__firstAppointmentDate__")}</label>
							</div>
							<div className="col-6">
								<OnlyDatePicker startDate={startDate} setStartDate={setStartDate} userLocale={userLocale} />
							</div>

							<div className="col-6">
								<OnlyTimePicker startTime={startTime} setStartTime={setStartTime} userLocale={userLocale} />
							</div>

							<div className="col-12 mt-4">
								<label>{t("calendar.__lastAppointmentDate__")}</label>
							</div>
							<div className="col-6">
								<OnlyDatePicker startDate={endDate} setStartDate={setEndDate} userLocale={userLocale} />
							</div>
							<div className="col-6">
								<OnlyTimePicker startTime={endTime} setStartTime={setEndTime} userLocale={userLocale} />
							</div>
						</div>
					) : null
				) : (
					<div className="row">
						<div className="col-12 mt-2">
							<label>{t("calendar.__eventDates__")}</label>
						</div>
						<div className="col-6">
							<OnlyDatePicker startDate={startDate} setStartDate={setStartDate} userLocale={userLocale} />
						</div>

						<div className="col-6">
							<OnlyTimePicker startTime={startTime} setStartTime={setStartTime} userLocale={userLocale} />
						</div>

						<div className="col-12 mt-4">
							<label>{t("calendar.__eventEndDates__")}</label>
						</div>
						<div className="col-6">
							<OnlyDatePicker startDate={endDate} setStartDate={setEndDate} userLocale={userLocale} />
						</div>
						<div className="col-6">
							<OnlyTimePicker startTime={endTime} setStartTime={setEndTime} userLocale={userLocale} />
						</div>
					</div>
				)}
			</div>
			<button type="submit" className="btn btn-primary mt-4">
				{t("common.__save__")}
			</button>
		</form>
	);
}
