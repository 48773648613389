export const ADMIN = -1;
export const SUPER_ADMIN = -10;

export const CAN_MANAGE_USER_AND_BADGE = 50;

export const CAN_MANAGE_CHECKER_RULE = 60;

export const HW_BUDGET_DISTRIBUTION = 70;

export const CAN_READ_ACTIVITIES = 80;
export const CAN_READ_ALL_MEMOT = 90;

export const CAN_UPDATE_HOME = 102;
export const CAN_MANAGE_HOME_DOCUMENTS = 103;

export const CAN_MANAGE_HOME_UNIT = 110;

export const CAN_CREATE_SENIOR = 121;
export const CAN_UPDATE_SENIOR = 122;
export const CAN_DELETE_SENIOR = 123;
export const CAN_MANAGE_SENIOR_DOCUMENTS = 124;
// export const CAN_UPLOAD_SENIOR_AVATAR = 129;

export const CAN_MODERATE_SENIOR_AVATAR = 130;
export const CAN_MODERATE_SENIOR_LIFE_STORIES = 131;

export const CAN_MODERATE_PHOTO_FRAME = 140;

export const CAN_MANAGE_TIMELINE_FILTERS = 250;
export const CAN_MANAGE_PERMISSIONS = 251;
export const CAN_MANAGE_HOME_CALENDAR = 252;

export const CAN_CHECK_NOTIFICATIONS = 260;

export const ALL_FAMILY_USER_MESSAGES = 270;
export const MY_FAMILY_USER_MESSAGES = 271;
export const CAN_INITIATE_MESSAGES_TO_FAMILY = 272;
export const CONTROL_PANEL = 275;
export const DIAPER_APPLICATION = 276;

export const HOME_BLOG_WRITER = 501;
export const CAN_MODERATE_HOME_BLOG = 502;
export const CAN_POST_MODIFY_HOME_BLOG = 503;
