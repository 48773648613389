import React from "react";
import { FaTimes } from "react-icons/fa";
import MediaPlayer from "./MediaPlayer";

export const DisplayVideoModal = ({ showVideo, setShowVideo }) => {
	const content = showVideo.show && (
		<div className="overlay">
			<div className="dialog py-2 px-2">
				<div
					className="btn btn-sm btn-primary right"
					type="button"
					onClick={() => setShowVideo({ show: false, video: null })}>
					<FaTimes />
				</div>
				<div className="mt-5">
					<MediaPlayer uriVideo={showVideo.video.path} contentType={showVideo.video.content_type} />
				</div>
			</div>
		</div>
	);
	return content;
};

export const DisplayPictureModal = ({ show, setShow }) => {
	const content = show.show && (
		<div className="overlay">
			<div className="dialog px-2 py-2">
				<div
					className="btn btn-sm btn-primary mb-2 right"
					type="button"
					onClick={() => setShow({ show: false, picture: null, type: null })}>
					<FaTimes />
				</div>
				<div>
					{show.picture ? (
						show.type === "blob" ? (
							<img src={show.picture} className="slideshow" alt="post illustration" />
						) : show.type === "initial" ? (
							<img
								src={`/storages/blog_media${show.picture}`}
								className="slideshow"
								alt="post illustration"
							/>
						) : null
					) : null}
				</div>
			</div>
		</div>
	);
	return content;
};
