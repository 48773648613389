import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import MyAccount from "../components/User/MyAccount";
import http from "../services/interceptor";
import * as url from "../constants/Url";

interface MyAccountProps {
	userDispatch: Function;
}

export default function UserAccount({ userDispatch }: MyAccountProps) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const [update, setUpdate] = useState(false);
	const [user, setUser] = useState(null);

	useEffect(() => {
		http.get(`${url.BASE_URL}/me`).then(res => setUser(res.data.user));
	}, [update]);

	const handleLogout = () => {
		sessionStorage.clear();
		localStorage.clear();
		userDispatch({
			type: "LOGOUT"
		});
		http.post(`${url.BASE_URL}/logout`);
		history.push("/");
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				<div>
					<h1>{t("myAccount.__myAccount__")}</h1>
				</div>
				<div>
					<button className="btn btn-primary" type="button" onClick={() => handleLogout()}>
						{t("login.__logout__")}
					</button>
				</div>
			</div>
			<div className="content-layout">
				{user ? (
					<MyAccount user={user} setUpdate={setUpdate} />
				) : (
					<p className="meta">{t("common.__noData__")}</p>
				)}
			</div>
		</>
	);
}
