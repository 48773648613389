import React, { createContext, useReducer, useState } from "react";
import UserReducer from "./UserReducer";
import User from "./UserClass";

const initialState = {
	isLogged: JSON.parse(sessionStorage.getItem("isLogged")) || false,
	homeUnits: [],
	seniors: [],
	userConnected:
		new User(
			JSON.parse(localStorage.getItem("firstName")),
			JSON.parse(localStorage.getItem("lastName")),
			JSON.parse(localStorage.getItem("type")),
			JSON.parse(localStorage.getItem("permissions")),
			JSON.parse(localStorage.getItem("homeId")),
			JSON.parse(localStorage.getItem("id")),
			JSON.parse(localStorage.getItem("locale"))
		) || null
};

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
	const [userState, userDispatch] = useReducer(UserReducer, initialState);
	const [selectedDate, setSelectedDate] = useState(new Date());

	return (
		<UserContext.Provider
			value={{
				userState,
				userDispatch,
				selectedDate,
				setSelectedDate
			}}>
			{children}
		</UserContext.Provider>
	);
};

export const UserContextConsumer = UserContext.Consumer;
