import React from "react";
import "./App.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { UserContextProvider } from "../context/UserContext";
import { BlogContextProvider } from "../context/BlogContext";
import Login from "../pages/Login";
import Footer from "../layout/Footer";
import NewPassword from "../components/User/NewPassword";
import ScrollToTop from "../hooks/ScrollToTop";
import ProtectedApp from "./ProtectedApp";

function App() {
	return (
		<UserContextProvider>
			<BlogContextProvider>
				<BrowserRouter>
					<ScrollToTop />
					<Switch>
						<Route exact path="/newpassword/:token" component={NewPassword} />
						<Route exact path="/" component={Login} />
						<ProtectedApp />
					</Switch>
					<Footer />
				</BrowserRouter>
			</BlogContextProvider>
		</UserContextProvider>
	);
}

export default App;
