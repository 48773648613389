import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { BsX } from "react-icons/bs";
import Header from "../../layout/Header";
import LostPassword from "./LostPassword";
import http from "../../services/interceptor";
import * as url from "../../constants/Url";

const NewPassword = () => {
	const { t } = useTranslation("common");
	const { token } = useParams<{ token?: string }>();
	const history = useHistory();
	const [httpResponse, setHttpResponse] = useState(false);
	const [errorPassword, setErrorPassword] = useState(false);
	const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
	const [displayNewPassword, setDisplayNewPassword] = useState(token ? true : false);
	const [password, setPassword] = useState<null | string>(null);
	const [confirmPassword, setConfirmPassword] = useState<null | string>(null);
	const [viewPassword, setViewPassword] = useState(false);
	const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

	useEffect(() => {
		if (token) {
			http
				.post(`${url.BASE_URL}/lost_password/token/validity`, { token: token })
				.then(res => setDisplayNewPassword(res.data.is_valid))
				.catch(() => setDisplayNewPassword(false));
		}
	}, [token]);

	const onSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		setErrorPassword(false);
		setErrorConfirmPassword(false);
		setHttpResponse(false);

		if (
			password &&
			!/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[²€£µ<>^!@=#$%^&*()`§_+,.\\/;':"-]).{8,}$/.test(
				password
			)
		) {
			setErrorPassword(true);
		} else if (password && password !== confirmPassword) {
			setErrorConfirmPassword(true);
		} else {
			const data = {
				token: token,
				password: password,
				confirm_password: confirmPassword
			};
			http
				.post(`${url.BASE_URL}/lost_password/recovery`, data)
				.then(() => {
					history.push("/");
				})
				.catch(() => {
					setHttpResponse(true);
				});
		}
	};

	return (
		<div className="main-layout">
			<Header userState={null} />
			<div className="layout">
				<div className="login-container mx-auto">
					<div className="center mb-5 mt-2">
						<h1>{t("login.__createPassword__")}</h1>
					</div>

					{errorPassword && (
						<p className="error">
							{t("users.__passwordWarning__")}
							<span className="right" onClick={() => setErrorPassword(false)} role="button">
								<BsX />
							</span>
						</p>
					)}
					{errorConfirmPassword && (
						<p className="error">
							{t("login.__passwordVerification__")}
							<span className="right" onClick={() => setErrorConfirmPassword(false)} role="button">
								<BsX />
							</span>
						</p>
					)}
					{httpResponse && (
						<p className="error">
							{t("users.__newPasswordError__")}
							<span className="right" onClick={() => setErrorConfirmPassword(false)} role="button">
								<BsX />
							</span>
						</p>
					)}
					{displayNewPassword ? (
						<form onSubmit={onSubmit}>
							<div className="alert alert-warning mt-4" role="alert">
								{t("users.__passwordWarning__")}
							</div>
							<div className="form-group mt-2 input-password mt-4">
								<label htmlFor="password">{t("common.__password__")}</label>
								<input
									type={viewPassword ? "text" : "password"}
									id="password"
									className="form-control"
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
									required
								/>
								<FaEye
									className="view-password fake-link"
									onClick={() => setViewPassword(prevState => !prevState)}
								/>
								<small id="passwordHelp" className="form-text text-muted right">
									{t("common.__required__")}
								</small>
							</div>
							<div className="form-group mt-2 input-password mt-4">
								<label htmlFor="confirmPassword">{t("common.__confirmPassword__")}</label>
								<input
									type={viewConfirmPassword ? "text" : "password"}
									id="confirmPassword"
									className="form-control"
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
									required
								/>
								<FaEye
									className="* fake-link"
									onClick={() => setViewConfirmPassword(prevState => !prevState)}
								/>
								<small id="confirmPasswordHelp" className="form-text text-muted right">
									{t("common.__required__")}
								</small>
							</div>
							<button type="submit" className="btn btn-secondary mt-3 center">
								{t("common.__save__")}
							</button>
						</form>
					) : (
						<>
							<div className="center">{t("users.__newPasswordTokenWarning__")}</div>
							<LostPassword displayTitle={false} />
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default NewPassword;
