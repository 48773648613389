import React, { useEffect } from "react";
import http from "../../../services/interceptor";
import * as url from "../../../constants/Url";
import { FaRegCommentAlt, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import HandleLocale from "../../../utils/HandleLocale";
import AddCommentForm from "./AddCommentForm";

export default function CommentsList({
	postId,
	commentStore,
	commentDispatch,
	userState,
	hwTypes,
	answerComment,
	setAnswerComment,
	blogDispatch
}) {
	const comments = commentStore.comments;
	const userLocale = HandleLocale(userState.userConnected.locale);
	const userId = userState.userConnected.id;

	useEffect(() => {
		if (postId) {
			http
				.get(`${url.HOMES_URL}/blog_posts/${postId}/comments`)
				.then(res => {
					commentDispatch({
						type: "GET_COMMENTS",
						payload: res.data
					});
				})
				.catch(() => {
					commentDispatch({
						type: "GET_COMMENTS",
						payload: []
					});
				});
		}
	}, [postId]);

	// Function to order the comments list :
	// put answers after initial comment, and order them chronologicaly

	const commentsToDisplay = comments => {
		let dataToDisplay = [];

		comments.map(comment => {
			if (!comment.is_after) {
				dataToDisplay.push(comment);
			} else {
				const firstComment = dataToDisplay.find(elt => elt.id === comment.after_comment_id);
				const addAnswerToComment = {
					...firstComment,
					answers: firstComment.answers ? [...firstComment.answers, comment] : [comment]
				};
				const indexOfThisFirstComment = dataToDisplay.findIndex(
					elt => elt.id === comment.after_comment_id
				);
				dataToDisplay.splice(indexOfThisFirstComment, 1, addAnswerToComment);
			}
		});

		const orderedDataToDisplay = dataToDisplay.map(data => {
			return {
				...data,
				answers: data.answers ? data.answers.sort((a, b) => a.datetime.localeCompare(b.datetime)) : []
			};
		});

		return displayComments(orderedDataToDisplay);
	};

	const displayUserBullet = (firstName, lastName) => {
		if (firstName) {
			if (firstName === "badge_general") {
				return <FaUser />;
			} else {
				return (
					<b>
						{firstName.substr(0, 1)}
						{lastName.substr(0, 1)}
					</b>
				);
			}
		} else {
			return <FaUser />;
		}
	};

	const displayComments = comments => {
		return (
			<>
				{comments.map((comment, index) => (
					<div key={index}>
						<div className="comment-container">
							<div className="comment-user-bullet">
								<div
									className={
										comment.is_family
											? comment.user.type === "principal"
												? "family-bullet principal"
												: "family-bullet affiliate"
											: comment.user.id === userId
											? "user-bullet me"
											: "user-bullet other"
									}>
									{displayUserBullet(comment.user.first_name, comment.user.last_name)}
								</div>
							</div>
							<div className="comment-card">
								{comment.datetime ? (
									<span className="right meta">
										{format(new Date(comment.datetime), "Pp", { locale: userLocale })}
									</span>
								) : null}
								{comment.user &&
									(comment.is_family ? (
										<>
											{comment.senior_id ? (
												<Link to={`/senior/profile/${comment.senior_id}`}>
													<b>
														{comment.user.first_name} {comment.user.last_name}
														<span className="meta">
															- {comment.user.type === "principal" ? "Aidant principal" : "Proche"}
														</span>
													</b>
												</Link>
											) : (
												<b>
													{comment.user.first_name} {comment.user.last_name}{" "}
													<span className="meta">
														- {comment.user.type === "principal" ? "Aidant principal" : "Proche"}
													</span>
												</b>
											)}
										</>
									) : (
										<b>
											{comment.user.first_name === "badge_general" ? null : comment.user.first_name}{" "}
											{comment.user.last_name} <span className="meta">- {comment.user.type}</span>
										</b>
									))}
								<p className="mt-2 mb-2">{comment.content}</p>

								<div className="mb-2">
									{answerComment.answer && answerComment.id === comment.id ? (
										<AddCommentForm
											postId={postId}
											commentStore={commentStore}
											commentDispatch={commentDispatch}
											userState={userState}
											answerComment={answerComment}
											setAnswerComment={setAnswerComment}
											hwTypes={hwTypes}
											blogDispatch={blogDispatch}
										/>
									) : (
										<div
											className="comment-answer-btn right"
											onClick={() => setAnswerComment({ answer: true, id: comment.id })}>
											<FaRegCommentAlt className="mr-1" /> répondre
										</div>
									)}
								</div>
							</div>
						</div>

						{comment.answers
							? comment.answers.map((elt, index) => (
									<div className="comment-is-after-container" key={index}>
										<div className="comment-user-bullet">
											<div
												className={
													elt.is_family
														? elt.user.type === "principal"
															? "family-bullet principal"
															: "family-bullet affiliate"
														: elt.user.id === userId
														? "user-bullet me"
														: "user-bullet other"
												}>
												{displayUserBullet(elt.user.first_name, elt.user.last_name)}
											</div>
										</div>
										<div className="comment-card">
											{elt.user ? (
												<p>
													{elt.datetime ? (
														<span className="right meta">
															{format(new Date(elt.datetime), "Pp", { locale: userLocale })}
														</span>
													) : null}
													<b>
														{elt.user.first_name === "badge_general" ? null : elt.user.first_name}{" "}
														{elt.user.last_name} <span className="meta">- {elt.user.type}</span>
													</b>
												</p>
											) : null}
											<div className="light-separator"></div>
											<p>{elt.content}</p>
										</div>
									</div>
							  ))
							: null}
					</div>
				))}
			</>
		);
	};

	return commentsToDisplay(comments);
}
