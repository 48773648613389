import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import http from "../../services/interceptor";
import * as url from "../../constants/Url";
import DisplayName from "../../utils/DisplayName";
import { BsArrowReturnRight } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { SeniorInterface } from "../../interfaces/UserStateInterface";

interface WhoLikeProps {
	show: { show: boolean; postId: number | null };
	setShow: Function;
	seniors: SeniorInterface[];
}

interface FamilyLikesInterface {
	id: number;
	principal: boolean;
	first_name: string;
	last_name: string;
	senior_ids: number[];
}

export default function WhoLikeModal({ show, setShow, seniors }: WhoLikeProps) {
	const { t } = useTranslation("common");
	const [familyLikes, setFamilyLikes] = useState<FamilyLikesInterface[]>([]);

	useEffect(() => {
		if (show.postId) {
			http
				.get(`${url.HOMES_URL}/blog_posts/${show.postId}/who/like`)
				.then(res => setFamilyLikes(res.data.family_users))
				.catch(() => setFamilyLikes([]));
		}
	}, [show.postId]);

	return show.show ? (
		<div className="overlay">
			<div className="dialog px-4 py-4">
				<div
					className="btn btn-sm btn-outline-secondary right"
					role="button"
					onClick={() => setShow({ show: false, postId: null })}>
					<FaTimes />
				</div>
				<h2>{t("news.__like__")}</h2>
				<div className="separator"></div>
				{familyLikes.length > 0 ? (
					familyLikes.map(family => (
						<div
							key={family.id}
							className={family.principal ? "family-outline-badge blue" : "family-outline-badge yellow"}>
							<span className="post-list-title">
								{family.first_name} {family.last_name}
							</span>
							<br />
							<BsArrowReturnRight />
							{family.senior_ids.map((senior, index) => (
								<span className="meta ml-1" key={index}>
									{DisplayName(senior, seniors)}
								</span>
							))}
						</div>
					))
				) : (
					<p className="meta">{t("common.__noData__")}</p>
				)}
			</div>
		</div>
	) : null;
}
