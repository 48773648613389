import React from "react";
import { useTranslation } from "react-i18next";
import { BsX } from "react-icons/bs";
import { EventInterface } from "../../interfaces/EventInterface";
import http from "../../services/interceptor";
import * as url from "../../constants/Url";

interface SuccessProps {
	setShow: Function;
}

export function RemoveEventSuccessMessage({ setShow }: SuccessProps) {
	const { t } = useTranslation("common");

	return (
		<div className="success">
			<div className="d-flex justify-content-between">
				<h5>{t("calendar.__removeAnimation__")}</h5>
				<div className="right" onClick={() => setShow({ event: null, show: false, success: false })}>
					<BsX className="big-kpi-icon" style={{ marginTop: "0.35rem" }} />
				</div>
			</div>
		</div>
	);
}

interface RemoveButtonsProps {
	event: EventInterface;
	setError: Function;
	setShow: Function;
}

export function RemoveEventButtons({ event, setError, setShow }: RemoveButtonsProps) {
	const { t } = useTranslation("common");

	const removeEvent = (eventId: number, type: string) => {
		setError(false);
		if (eventId && type) {
			let deleteUrl;
			if (type === "ALL_EVENTS") {
				deleteUrl = `${url.BASE_URL}/home_calendar_entries/${eventId}/recurring/all`;
			} else if (type === "EVENTS_AFTER_THIS_DATE") {
				const today = event?.begin_datetime.split(" ").join("T");
				deleteUrl = `${url.BASE_URL}/home_calendar_entries/${eventId}/recurring/greater_than/${today}`;
			} else {
				deleteUrl = `${url.BASE_URL}/home_calendar_entries/${eventId}`;
			}
			http
				.delete(deleteUrl)
				.then(() => setShow({ show: false, event: null, success: true }))
				.catch(() => setError(true));
		}
	};

	return event.recurring_entry_id ? (
		<div className="btn-group" role="group" aria-label="Select Event Type">
			<button
				type="button"
				onClick={() => removeEvent(event.id, "EVENT")}
				className="btn btn-outline-primary">
				{t("calendar.__removeEvent__")}
			</button>
			<button
				type="button"
				onClick={() => removeEvent(event.id, "EVENTS_AFTER_THIS_DATE")}
				className="btn btn-outline-primary">
				{t("calendar.__removeNextEvents__")}
			</button>
			<button
				type="button"
				onClick={() => removeEvent(event.id, "ALL_EVENTS")}
				className="btn btn-outline-primary">
				{t("calendar.__removeAllEvents__")}
			</button>
		</div>
	) : (
		<button
			className="btn btn-outline-primary"
			type="button"
			onClick={() => removeEvent(event.id, "EVENT")}>
			{t("calendar.__removeEvent__")}
		</button>
	);
}
