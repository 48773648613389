import React from "react";
import HandleLocale from "../HandleLocale";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";

interface Props {
	startTime: Date | null;
	setStartTime: Function;
	userLocale: string;
}

const OnlyTimePicker = ({ startTime, setStartTime, userLocale }: Props) => {
	const locale = HandleLocale(userLocale);

	return (
		<DatePicker
			selected={startTime}
			onChange={date => setStartTime(date)}
			showTimeSelect
			showTimeSelectOnly
			dateFormat="p"
			timeFormat="p"
			locale={locale}
			timeIntervals={15}
			// timeCaption="Heure"
			className="form-control form-control-lg"
		/>
	);
};
export default OnlyTimePicker;
