import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BsX } from "react-icons/bs";
import http from "../../../services/interceptor";
import * as url from "../../../constants/Url";
import HandleError from "../../../layout/HandleError";
import { FileInterface, PostInterface } from "../../../interfaces/BlogInterface";
import { FaCamera, FaCheck, FaRegClock, FaUsersCog, FaVideo } from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";

interface ModeratePostProps {
	show: { post: PostInterface | null; show: boolean };
	setShow: Function;
	userLocale: string;
	blogDispatch: Function;
}

export const ModeratePostModal = ({
	show,
	setShow,
	userLocale,
	blogDispatch
}: ModeratePostProps) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const [error, setError] = useState(false);

	const newsValidation = () => {
		const id = show.post ? show.post.id : null;
		if (id) {
			const data = {
				accepted: true
			};
			http
				.put(`${url.HOMES_URL}/blog_posts/${id}/moderate`, data)
				.then(res => {
					blogDispatch({
						type: "EDIT_POST",
						payload: { post: res.data.blog_post, locale: userLocale }
					});
					history.push(`/post/${id}`);
				})
				.catch(() => setError(true));
		}
	};

	const handleMedias = (medias: FileInterface[]) => {
		const videoList = medias.filter(
			(media: FileInterface) => media.content_type.split("/")[0] === "video"
		);
		const photoCounter = medias.length - videoList.length;

		return (
			<>
				<span className="mr-4">
					<FaVideo className="mr-1" /> {videoList.length}
				</span>
				<span className="mr-4">
					<FaCamera className="mr-1" /> {photoCounter}
				</span>
			</>
		);
	};

	return show.show ? (
		<div className="overlay">
			<div className="dialog px-4 py-4">
				<div
					className="btn btn-sm btn-outline-secondary right"
					role="button"
					onClick={() => setShow({ show: false, postId: null })}>
					<BsX />
				</div>
				<h2>{t("news.__moderateThePost__")}</h2>
				<div className="separator"></div>
				{error ? (
					<HandleError message={t("common.__errorOccured__")} type="error" setError={setError} />
				) : null}
				{show.post ? (
					<div className="header-details">
						<p>
							{t("news.__title__")} : <b>{show.post.title}</b>
						</p>
						<p className="meta">
							<span className="mr-4">
								<FaRegClock className="mr-2" /> {handleDate(show.post.date, "P", userLocale)}
							</span>
							{handleMedias(show.post.medias)}
							{show.post.seniors_focused_on ? (
								<>
									<FaUsersCog className="ml-2 mr-2" style={{ height: "1rem", width: "auto" }} />
									{show.post.seniors_focused_on.length}
								</>
							) : null}
						</p>
					</div>
				) : null}
				<button
					type="button"
					className="btn btn-sm btn-outline-secondary mr-2"
					onClick={() => history.push(`/myposts`)}>
					{t("news.__moderateLater__")}
				</button>
				<button type="button" className="btn btn-sm btn-primary" onClick={() => newsValidation()}>
					<FaCheck className="mr-2" /> {t("news.__publishPost__")}
				</button>
			</div>
		</div>
	) : null;
};
