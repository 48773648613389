import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { addWeeks, addMonths, format, subMonths, subWeeks, addDays, subDays } from "date-fns";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";

interface Props {
	selectedDate: Date;
	setSelectedDate: Function;
	locale: any;
	view: string;
}

export default function CalendarHeader({ selectedDate, setSelectedDate, locale, view }: Props) {
	const { t } = useTranslation("common");
	const history = useHistory();

	const handlePreviousDate = () => {
		if (view === "week") {
			setSelectedDate(subWeeks(selectedDate, 1));
		} else if (view === "day") {
			setSelectedDate(subDays(selectedDate, 1));
		} else {
			setSelectedDate(subMonths(selectedDate, 1));
		}
	};

	const handleNextDate = () => {
		if (view === "week") {
			setSelectedDate(addWeeks(selectedDate, 1));
		} else if (view === "day") {
			setSelectedDate(addDays(selectedDate, 1));
		} else {
			setSelectedDate(addMonths(selectedDate, 1));
		}
	};

	return (
		<div className="d-flex justify-content-between">
			<div>
				<FaArrowCircleLeft className="navIcon" onClick={() => handlePreviousDate()} />
				<FaArrowCircleRight className="navIcon" onClick={() => handleNextDate()} />
				<span className="ml-3">
					<b>
						{view === "day"
							? format(selectedDate, "PPPP", { locale: locale })
							: format(selectedDate, "MMMM yyyy", { locale: locale })}
					</b>
				</span>
			</div>
			<div className="btn-group" role="group" aria-label="Calendar Navigation">
				<button
					type="button"
					onClick={() => history.push("/calendar")}
					className={view === "month" ? `btn btn-primary` : `btn btn-outline-primary`}>
					{t("calendar.__month__")}
				</button>
				<button
					type="button"
					onClick={() => history.push("/calendweek")}
					className={view === "week" ? `btn btn-primary` : `btn btn-outline-primary`}>
					{t("calendar.__week__")}
				</button>
			</div>
		</div>
	);
}
