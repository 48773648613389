import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface Props {
	title: string;
}

export default function BlogHeaderNav({ title }: Props) {
	const { t } = useTranslation("common");
	const history = useHistory();

	return (
		<div className="d-flex justify-content-between align-items-center">
			<div>
				<h1>{title}</h1>
			</div>
			<div className="btn-group" role="group" aria-label="Blog Navigation">
				<button
					type="button"
					onClick={() => history.push("/flow")}
					className={title === t("common.__newsFeed__") ? `btn btn-primary` : `btn btn-outline-primary`}>
					{t("common.__newsFeed__")}
				</button>
				<button
					type="button"
					onClick={() => history.push("/myposts")}
					className={title === t("news.__handleNews__") ? `btn btn-primary` : `btn btn-outline-primary`}>
					{t("news.__handleNews__")}
				</button>
			</div>
		</div>
	);
}
