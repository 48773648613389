import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
	const { t } = useTranslation("common");

	return (
		<footer>
			<p className="meta center">
				© {process.env.REACT_APP_NAME} v.1.4.3 -
				<a href={`mailto:${process.env.REACT_APP_SUPPORT}`}> {t("common.__techSupport__")}</a>
			</p>
		</footer>
	);
}
