import React, { useContext, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { FaPlus } from "react-icons/fa";
import Header from "./Header";
import Login from "../pages/Login";

interface Props {
	children: ReactNode;
}

export default function MainLayout({ children }: Props) {
	const { userState, userDispatch } = useContext(UserContext);
	const location = useLocation();

	return userState.isLogged ? (
		<div className="main-layout">
			<Header userState={userState} userDispatch={userDispatch} />
			<div className="layout">{children}</div>
			{location.pathname.includes("livemeeting") || location.pathname.includes("blog") ? null : (
				<Link to="/blog/addpost">
					<button type="button" className="floating-button">
						<FaPlus className="floating-icon" />
					</button>
				</Link>
			)}
		</div>
	) : (
		<Login />
	);
}
