import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";
import nl from "date-fns/locale/nl-BE";

export default function HandleLocale(locale: string) {
	if (locale === "fr") {
		return fr;
	} else if (locale === "nl") {
		return nl;
	} else {
		return en;
	}
}
