import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SeniorInterface, UserInterface } from "../interfaces/UserStateInterface";
import { BsFillPinAngleFill } from "react-icons/bs";
import { EventInterface, OccupationInterface } from "../interfaces/EventInterface";
import { getActivityAction } from "../constants/Activities";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import UpdateAttendees from "../components/Occupation/UpdateAttendees";
import AddAttendees from "../components/Occupation/AddAttendees";
import http from "../services/interceptor";
import * as url from "../constants/Url";
interface Props {
	userState: UserInterface;
	selectedDate: Date;
	setSelectedDate: Function;
}

export default function Occupation({ userState, selectedDate, setSelectedDate }: Props) {
	const { t } = useTranslation("common");
	const homeId = userState.userConnected.home_id;
	const seniors = userState.seniors;
	const homeUnits = userState.homeUnits;
	const { eventId } = useParams<{ eventId?: string }>();
	const { occupationId } = useParams<{ occupationId?: string }>();
	const [occupation, setOccupation] = useState<OccupationInterface | null>(null);
	const [event, setEvent] = useState<EventInterface | null>(null);

	useEffect(() => {
		if (eventId) {
			http
				.get(`${url.BASE_URL}/home_calendar_entries/${eventId}`)
				.then(res => setEvent(res.data.calendar_entry))
				.catch(() => setOccupation(null));
		}
	}, [eventId]);

	useEffect(() => {
		if (occupationId) {
			http
				.get(`${url.BASE_URL}/occupations/${occupationId}`)
				.then(res => {
					const result = res.data.occupation;
					const seniorIds = result.senior_ids;
					const attendees = seniors.filter((senior: SeniorInterface) => seniorIds.includes(senior.id));
					const occupationWithSeniors = {
						...result,
						seniors: attendees,
						activity: getActivityAction(result.action_id)
					};
					setOccupation(occupationWithSeniors);
				})
				.catch(() => setOccupation(null));
		}
	}, [occupationId]);

	return homeId ? (
		<>
			<h1>{t("calendar.__seniorsIn__")}</h1>
			<div className="separator"></div>
			<div className="content-layout">
				{event ? (
					<>
						<h3>
							<BsFillPinAngleFill className="mr-2" /> {t("calendar.__eventName__")} : {event.info}
						</h3>
						<p className="meta">
							{t("common.__date__")} :{" "}
							{handleDate(event.begin_datetime, "PPPPp", userState.userConnected.locale)}
						</p>
						{event.detail ? (
							<p className="meta">
								{t("calendar.__eventDetails__")} : {event.detail}
							</p>
						) : null}
					</>
				) : (
					<p>
						<b>
							<BsFillPinAngleFill className="mr-2" /> {t("calendar.__workshop__")}
						</b>
					</p>
				)}
				{occupationId ? (
					<UpdateAttendees
						seniors={seniors}
						event={event}
						occupation={occupation}
						homeUnits={homeUnits}
					/>
				) : (
					<AddAttendees
						homeId={homeId}
						seniors={seniors}
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						event={event}
						userLocale={userState.userConnected.locale}
						homeUnits={homeUnits}
					/>
				)}
			</div>
		</>
	) : (
		<div className="content-layout">
			<h2>{t("common.__accessDenied__")}</h2>
			<p className="error-msg mt-2">{t("common.__accessDeniedInfo__")}</p>
		</div>
	);
}
