import React from "react";
import Posts from "../components/Blog/Flow/Posts";
import { useTranslation } from "react-i18next";
import BlogHeaderNav from "../layout/BlogHeaderNav";
import { UserInterface } from "../interfaces/UserStateInterface";

interface FlowProps {
	userState: UserInterface;
}

export default function Flow({ userState }: FlowProps) {
	const { t } = useTranslation("common");

	return (
		<>
			<BlogHeaderNav title={t("common.__newsFeed__")} />
			<div className="content-layout">
				<Posts userState={userState} />
			</div>
		</>
	);
}
