import React from "react";
import { BsPersonCheck, BsPersonPlusFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { EventInterface } from "../../interfaces/EventInterface";
import { SeniorInterface } from "../../interfaces/UserStateInterface";
import DisplayName from "../../utils/DisplayName";
import { displayStartVisioButton, displayTiming, displayCategory } from "./CalendarUtils";
interface Props {
	event: EventInterface;
	setShow: Function;
	view: string;
	setSelectedDate?: Function;
	seniors: SeniorInterface[];
}

export default function EventScheduled({ event, setShow, view, setSelectedDate, seniors }: Props) {
	const history = useHistory();
	const affectSeniors = () => {
		const eventDate = new Date(event.begin_datetime);
		if (setSelectedDate) {
			setSelectedDate(eventDate);
		}
		if (event.occupation_id) {
			history.push(`/occupation/${event.id}/${event.occupation_id}`);
		} else {
			history.push(`/occupation/${event.id}`);
		}
	};

	return (
		<div
			key={event.id}
			className={
				event.entry_type_id === 11
					? "calendar-card visio"
					: event.entry_type_id === 2
					? "calendar-card event"
					: "calendar-card workshop"
			}>
			{event.entry_type_id === 11 ? (
				<>
					<p className="type">
						{view === "day" ? displayCategory(event.entry_type_id) : displayTiming(event.begin_datetime)}
					</p>
					<p className="info">
						<span className="mr-3">
							{view === "day" ? displayTiming(event.begin_datetime) : event.info} :{" "}
							<b>{event.senior_id ? DisplayName(event.senior_id, seniors) : null}</b>
						</span>
					</p>
					{displayStartVisioButton(event, view)}
				</>
			) : (
				<div
					className={view === "day" ? "d-flex justify-content-between" : ""}
					role="button"
					onClick={() => setShow({ show: true, event: event })}>
					<div>
						<p className="type">
							{view === "day" ? displayCategory(event.entry_type_id) : displayTiming(event.begin_datetime)}
						</p>
						<p className="info">
							{view === "day" ? <span className="mr-2">{displayTiming(event.begin_datetime)}</span> : null}
							<b>{event.info}</b>
						</p>
					</div>
					{event.occupation_id ? (
						view === "day" ? (
							<div className="right mr-1 mt-1">
								<button type="button" className="btn btn-outline-primary" onClick={() => affectSeniors()}>
									<BsPersonCheck />
								</button>
							</div>
						) : (
							<BsPersonCheck className="kpi-icon" />
						)
					) : view === "day" ? (
						<div className="right mr-1 mt-1">
							<button type="button" className="btn btn-primary" onClick={() => affectSeniors()}>
								<BsPersonPlusFill />
							</button>
						</div>
					) : null}
				</div>
			)}
		</div>
	);
}
