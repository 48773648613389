import React, { useState, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaRegCommentAlt } from "react-icons/fa";
import AddCommentForm from "./AddCommentForm";
import CommentsList from "./CommentsList";
import CommentsReducer from "./CommentsReducer";
import "./Comment.scss";
import http from "../../../services/interceptor";
import * as url from "../../../constants/Url";

export default function Comments({ postId, commentsCounter, userState, blogDispatch }) {
	const { t } = useTranslation("common");
	const [addComment, setAddComment] = useState(commentsCounter === 0 ? true : false);
	const [answerComment, setAnswerComment] = useState({ answer: false, id: null });
	const [hwTypes, setHwTypes] = useState([]);

	const initialState = {
		comments: [],
		error: false,
		success: false
	};

	const [commentStore, commentDispatch] = useReducer(CommentsReducer, initialState);

	useEffect(() => {
		return function cleanup() {
			commentDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (hwTypes.length === 0) {
			http
				.get(`${url.BASE_URL}/health_worker_types`)
				.then(res => setHwTypes(res.data.health_worker_types))
				.catch(() => setHwTypes([]));
		}
	}, []);

	return (
		<div className="row">
			<div className="col-6">
				<FaRegCommentAlt className="mr-2 table-icon" /> {commentsCounter} {t("news.__comments__")}
			</div>
			<div className="col-6">
				{addComment ? null : (
					<button
						type="button"
						className="btn btn-sm btn-primary right"
						onClick={() => setAddComment(true)}>
						{t("news.__addComment__")}
					</button>
				)}
			</div>
			<div className="col-12 mt-2">
				{addComment ? (
					<AddCommentForm
						postId={postId}
						commentStore={commentStore}
						commentDispatch={commentDispatch}
						userState={userState}
						addComment={addComment}
						setAddComment={setAddComment}
						hwTypes={hwTypes}
						answerComment={answerComment}
						setAnswerComment={setAnswerComment}
						blogDispatch={blogDispatch}
					/>
				) : null}
			</div>
			<div className="col-12">
				<CommentsList
					postId={postId}
					commentStore={commentStore}
					commentDispatch={commentDispatch}
					userState={userState}
					hwTypes={hwTypes}
					answerComment={answerComment}
					setAnswerComment={setAnswerComment}
					blogDispatch={blogDispatch}
				/>
			</div>
		</div>
	);
}
