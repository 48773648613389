import React from "react";
import { useTranslation } from "react-i18next";
import { EventInterface } from "../../interfaces/EventInterface";
import { SeniorInterface, UserInterface } from "../../interfaces/UserStateInterface";
import EventDetailModal from "./EventDetailModal";
import "./Calendar.scss";
import CalendarDayViewContainer from "./CalendarDayViewContainer";
import { useHistory } from "react-router-dom";
import { BsCalendarPlus } from "react-icons/bs";
interface CalendarDayViewProps {
	userState: UserInterface;
	eventsList: EventInterface[];
	canManageCalendar: boolean;
	selectedDate: Date;
	setSelectedDate: Function;
	seniors: SeniorInterface[];
	show: { show: boolean; event: EventInterface | null; success: boolean };
	setShow: Function;
}

const CalendarDayView = ({
	userState,
	eventsList,
	selectedDate,
	setSelectedDate,
	canManageCalendar,
	seniors,
	show,
	setShow
}: CalendarDayViewProps) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const userLocale = userState.userConnected.locale;

	return (
		<>
			<hr />
			{eventsList.length === 0 ? (
				<>
					<p className="meta mt-3">{t("calendar.__noAppointment__")}</p>
					{canManageCalendar ? (
						<button
							type="button"
							className="btn btn-outline-primary"
							onClick={() => history.push(`/addevent`)}>
							<BsCalendarPlus className="mr-2" />
							{t("postsNavigation.__create__")}
						</button>
					) : null}
				</>
			) : (
				<div className="calenday-container">
					<CalendarDayViewContainer
						eventsList={eventsList}
						setShow={setShow}
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						canManageCalendar={canManageCalendar}
						seniors={seniors}
					/>
				</div>
			)}
			<EventDetailModal show={show} setShow={setShow} userLocale={userLocale} seniors={seniors} />
		</>
	);
};

export default CalendarDayView;
