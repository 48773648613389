import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DisplayPictureModal } from "../NewsMediaModal";
import { BsCardImage } from "react-icons/bs";
import { fileSize } from "../../../utils/Blogging";
import { FaTimes } from "react-icons/fa";

export default function PicturesManager({
	medias,
	setMedias,
	setUploadSize,
	initialMedias,
	setInitialMedias
}) {
	const { t } = useTranslation("common");
	const [showPicture, setShowPicture] = useState({ show: false, picture: null, type: null });

	const files = medias.filter(media => media.type.split("/")[0] === "image");
	const existingFiles =
		initialMedias.length > 0
			? initialMedias.filter(media => media.content_type.split("/")[0] === "image")
			: [];
	const filesCounter = existingFiles ? files.length + existingFiles.length : files.length;

	const removeInitialFiles = (path, size) => {
		const newInitialList = initialMedias.filter(f => f.path !== path);
		setInitialMedias(newInitialList);
		setUploadSize(uploadSize => uploadSize - size);
	};

	const removeFile = (name, size) => {
		const newFileList = medias.filter(f => f.name !== name);
		setMedias(newFileList);
		setUploadSize(uploadSize => uploadSize - size);
	};

	return (
		<>
			{(existingFiles && existingFiles.length > 0) || files.length > 0 ? (
				<div className="light-blue-block">
					<div className="mt-2 mb-4">
						<BsCardImage className="mr-2 blue" style={{ width: "24px", height: "auto" }} />
						{filesCounter} {t("news.__pictsAdded__")} :
					</div>
					<div className="d-flex flex-wrap">
						{existingFiles &&
							existingFiles.length > 0 &&
							existingFiles.map((file, index) => (
								<div className="preview-post" key={index}>
									<img
										src={`/storages/blog_media${file.path}`}
										className="preview-pict fake-link"
										alt="prévisualisation"
										onClick={() => setShowPicture({ show: true, picture: file.path, type: "initial" })}
									/>
									<p className="meta mt-1">
										{t("news.__size__")} : {fileSize(file.content_length)}
									</p>
									<button
										type="button"
										className="pict-btn"
										onClick={() => removeInitialFiles(file.path, file.content_length)}>
										<FaTimes style={{ marginLeft: "-2px" }} />
									</button>
								</div>
							))}
						{files.length > 0 &&
							files.map((file, index) => (
								<div className="preview-post" key={index}>
									<img
										src={file.preview}
										className="preview-pict fake-link"
										alt="prévisualisation"
										onClick={() => setShowPicture({ show: true, picture: file.preview, type: "blob" })}
									/>
									<p className="meta mt-1">
										{t("news.__size__")} : {fileSize(file.size)}
									</p>
									<button
										type="button"
										className="pict-btn"
										onClick={() => removeFile(file.name, file.size)}>
										<FaTimes style={{ marginLeft: "-2px" }} />
									</button>
								</div>
							))}
					</div>
				</div>
			) : null}
			<DisplayPictureModal show={showPicture} setShow={setShowPicture} />
		</>
	);
}
