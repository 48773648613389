import React, { useState, useEffect } from "react";
import { format, startOfWeek, endOfWeek } from "date-fns";
import { RemoveEventSuccessMessage } from "../components/Calendar/RemoveEvent";
import { UserInterface } from "../interfaces/UserStateInterface";
import { EventInterface } from "../interfaces/EventInterface";
import HandleLocale from "../utils/HandleLocale";
import CalendarWeekView from "../components/Calendar/CalendarWeekView";
import CalendarHeader from "../layout/CalendarHeader";
import http from "../services/interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface CalendWeekProps {
	userState: UserInterface;
	selectedDate: Date;
	setSelectedDate: Function;
}

const CalendWeek = ({ userState, selectedDate, setSelectedDate }: CalendWeekProps) => {
	const seniors = userState.seniors;
	const [eventsList, setEventsList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const homeId = userState.userConnected.home_id;
	const locale = HandleLocale(userState.userConnected.locale);
	const canManageCalendar = userState.userConnected.is(p.CAN_MANAGE_HOME_CALENDAR);
	const [show, setShow] = useState({ show: false, event: null, success: false });

	const getEvents = () => {
		setIsLoading(true);
		const startOfTheSelectedWeek = startOfWeek(selectedDate, { weekStartsOn: 1 });
		const endOfTheSelectedWeek = endOfWeek(selectedDate, { weekStartsOn: 1 });
		const startDate = format(startOfTheSelectedWeek, "yyyy-MM-dd'T'00:00:00", { locale: locale });
		const endDate = format(endOfTheSelectedWeek, "yyyy-MM-dd'T'23:59:59", { locale: locale });

		http
			.get(
				`${url.HOMES_URL}/${homeId}/home_calendar_entries/from/${encodeURIComponent(
					startDate
				)}/to/${encodeURIComponent(endDate)}`
			)
			.then(res => {
				const result = res.data.calendar_entries;
				const events = result.map((event: EventInterface) => {
					return {
						...event,
						eventDate: event.begin_datetime.split(" ")[0],
						eventTiming:
							event.begin_datetime.split(" ")[1].split(":")[0] +
							":" +
							event.begin_datetime.split(" ")[1].split(":")[1]
					};
				});
				const orderedEvents = events.sort((a: EventInterface, b: EventInterface) =>
					a.begin_datetime.localeCompare(b.begin_datetime)
				);
				setIsLoading(false);
				setEventsList(orderedEvents);
			})
			.catch(() => {
				setIsLoading(false);
				setEventsList([]);
			});
	};

	useEffect(() => {
		if (homeId && selectedDate) {
			getEvents();
		}
		// eslint-disable-next-line
	}, [selectedDate, homeId]);

	useEffect(() => {
		if (homeId && selectedDate && show.success) {
			getEvents();
		}
		// eslint-disable-next-line
	}, [selectedDate, homeId, show.success]);

	return (
		<>
			<CalendarHeader
				selectedDate={selectedDate}
				setSelectedDate={setSelectedDate}
				view={"week"}
				locale={locale}
			/>
			{show.success ? <RemoveEventSuccessMessage setShow={setShow} /> : null}
			<CalendarWeekView
				isLoading={isLoading}
				eventsList={eventsList}
				selectedDate={selectedDate}
				setSelectedDate={setSelectedDate}
				locale={locale}
				canManageCalendar={canManageCalendar}
				seniors={seniors}
				show={show}
				setShow={setShow}
			/>
		</>
	);
};

export default CalendWeek;
