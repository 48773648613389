import React from "react";
import DatePicker from "react-datepicker";
import HandleLocale from "../HandleLocale";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";

interface Props {
	startDate: Date | null;
	setStartDate: Function;
	userLocale: string;
}

const OnlyDatePicker = ({ startDate, setStartDate, userLocale }: Props) => {
	const locale = HandleLocale(userLocale);

	return (
		<DatePicker
			selected={startDate}
			onChange={date => setStartDate(date)}
			locale={locale}
			dateFormat="PPPP"
			className="form-control form-control-lg"
		/>
	);
};
export default OnlyDatePicker;
