import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import http from "../../../services/interceptor";
import * as url from "../../../constants/Url";
import DisplayName from "../../../utils/DisplayName";
import format from "date-fns/format";
import HandleError from "../../../layout/HandleError";
import { useMutation, useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";

export default function AddCommentForm({
	postId,
	userState,
	commentStore,
	commentDispatch,
	addComment,
	setAddComment,
	hwTypes,
	answerComment,
	setAnswerComment,
	blogDispatch
}) {
	const { t } = useTranslation("common");
	const userJob = hwTypes.length > 0 ? DisplayName(userState.userConnected.type_id, hwTypes) : null;
	const [comment, setComment] = useState(null);
	const queryClient = useQueryClient();

	const onSubmit = async e => {
		e.preventDefault();
		commentDispatch({
			type: "RESET_ES"
		});

		if (comment) {
			let data = {};
			if (answerComment.answer) {
				data = {
					comment: {
						blog_id: postId,
						content: comment,
						user: {
							id: userState.userConnected.id,
							type: userJob,
							first_name: userState.userConnected.first_name,
							last_name: userState.userConnected.last_name
						},
						datetime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
						is_family: false,
						is_after: true,
						after_comment_id: answerComment.id,
						senior_id: null,
						id: uuidv4()
					}
				};
			} else {
				data = {
					comment: {
						blog_id: postId,
						content: comment,
						user: {
							id: userState.userConnected.id,
							type: userJob,
							first_name: userState.userConnected.first_name,
							last_name: userState.userConnected.last_name
						},
						datetime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
						is_family: false,
						is_after: false,
						after_comment_id: null,
						senior_id: null,
						id: uuidv4(),
						answers: []
					}
				};
			}
			await mutate(data);
		}
	};
	const { mutate } = useMutation(
		data => http.put(`${url.HOMES_URL}/blog_posts/${postId}/comments`, data),
		{
			onSuccess: res => {
				queryClient.invalidateQueries("blog_posts");
				commentDispatch({
					type: "ADD_COMMENT",
					payload: res.data
				});
				blogDispatch({
					type: "EDIT_POST_COUNTER",
					payload: postId
				});
				if (addComment) {
					setAddComment(false);
				}
				if (answerComment) {
					setAnswerComment({ answer: false, id: null });
				}
			},
			onError: () => {
				commentDispatch({
					type: "DISPLAY_ERROR"
				});
			}
		}
	);

	const handleCancelButton = () => {
		commentDispatch({
			type: "RESET_ES"
		});
		if (answerComment) {
			setAnswerComment({ answer: false, id: null });
		}
		if (addComment) {
			setAddComment(false);
		}
	};

	return (
		<>
			{commentStore.error ? (
				<HandleError dispatch={commentDispatch} message={t("common.__addError__")} type="dispatch" />
			) : null}
			<form onSubmit={onSubmit}>
				<textarea
					name="comment"
					cols="10"
					rows="4"
					className="form-control mb-2"
					onChange={e => setComment(e.target.value)}
				/>
				<div className="mb-4">
					<button
						type="button"
						className="btn btn-sm btn-outline-secondary mr-2"
						onClick={() => handleCancelButton()}>
						{t("common.__cancel__")}
					</button>
					<button type="submit" className="btn btn-primary btn-sm">
						{t("common.__send__")}
					</button>
				</div>
			</form>
		</>
	);
}
