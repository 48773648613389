import { useState, useEffect } from "react";

export default function useDeviceDetect() {
	const [isMobile, setMobile] = useState(false);
	const [isIos, setIos] = useState(false);

	useEffect(() => {
		const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
		const mobile = Boolean(
			userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)
		);
		const iOs = Boolean(
			userAgent.match(/iPhone|iPad|iPod/i)
		);
		setMobile(mobile);
		setIos(iOs);
	}, []);

	return { isMobile, isIos };
}
