import React from "react";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import {
	// FaBan,
	// FaExclamationTriangle,
	// FaRegCheckCircle,
	FaCamera,
	FaRegFilePdf,
	FaVideo
} from "react-icons/fa";
import { Link } from "react-router-dom";
// import i18n from "../../i18n/i18next";

// export const handleStatus = (accepted: boolean | null) => {
// 	switch (accepted) {
// 		case true:
// 			return (
// 				<span className="ml-3 blue">
// 					<FaRegCheckCircle
// 						className="mr-2"
// 						style={{ height: "16px", width: "auto", verticalAlign: "middle" }}
// 					/>
// 					<b>{i18n.t("news.__moderationOk__")}</b>
// 				</span>
// 			);
// 		case false:
// 			return (
// 				<span className="ml-3 red">
// 					<FaBan className="mr-2" style={{ height: "16px", width: "auto", verticalAlign: "middle" }} />
// 					<b>{i18n.t("news.__moderationRefused__")}</b>
// 				</span>
// 			);
// 		case null:
// 			return (
// 				<span className="ml-3 green">
// 					<FaExclamationTriangle
// 						className="mr-2"
// 						style={{ height: "16px", width: "auto", verticalAlign: "middle" }}
// 					/>
// 					<b>{i18n.t("news.__moderationPending__")}</b>
// 				</span>
// 			);
// 		default:
// 			return;
// 	}
// };

export const displayMeta = (medias: any) => {
	const video = medias.filter((media: any) => media.content_type.split("/")[0] === "video");
	const pdf = medias.filter((media: any) => media.content_type === "application/pdf");
	const videoCounter = video.length;
	const pdfCounter = pdf.length;
	const pictCounter = medias.length - (videoCounter + pdfCounter);
	return (
		<>
			<FaVideo className={`social-meta-icon mr-2 ${videoCounter > 0 ? "blue" : "grey"}`} />
			<span className={videoCounter > 0 ? "blue" : "grey"}>{videoCounter}</span>
			<FaCamera className={`social-meta-icon ml-4 mr-2 ${pictCounter > 0 ? "blue" : "grey"}`} />
			<span className={pictCounter > 0 ? "blue" : "grey"}>{pictCounter}</span>
			<FaRegFilePdf className={`social-meta-icon ml-4 mr-2 ${pdfCounter > 0 ? "red" : "grey"}`} />
			<span className={pdfCounter > 0 ? "red" : "grey"}>{pdfCounter}</span>
		</>
	);
};

export const handleEditRemoveButtons = (
	accepted: boolean,
	authorId: number,
	postId: number,
	title: string,
	isBlogger: boolean,
	canRemovePost: boolean,
	userId: number,
	isModerator: boolean,
	setShowRemoveConfirmation: Function
) => {
	if (canRemovePost) {
		if (accepted !== false) {
			return (
				<>
					<Link to={`/news/editpost/${postId}`}>
						<BsPencilSquare role="button" className="big-kpi-icon regular" />
					</Link>
					<BsTrash
						className="big-kpi-icon regular"
						role="button"
						onClick={() =>
							setShowRemoveConfirmation({
								postId: postId,
								postTitle: title
							})
						}
					/>
				</>
			);
		} else {
			return (
				<BsTrash
					className="big-kpi-icon regular"
					role="button"
					onClick={() =>
						setShowRemoveConfirmation({
							postId: postId,
							postTitle: title
						})
					}
				/>
			);
		}
	} else if (isModerator) {
		if (accepted === null) {
			return (
				<Link to={`/news/editpost/${postId}`}>
					<BsPencilSquare role="button" className="big-kpi-icon regular" />
				</Link>
			);
		}
	} else if (isBlogger && authorId === userId) {
		return (
			<Link to={`/news/editpost/${postId}`}>
				<BsPencilSquare role="button" className="big-kpi-icon regular" />
			</Link>
		);
	} else {
		return null;
	}
};
