import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CAN_POST_MODIFY_HOME_BLOG, HOME_BLOG_WRITER } from "../constants/Permissions";
import { BlogContext } from "../context/BlogContext";
import { UserInterface } from "../interfaces/UserStateInterface";
import AccessControl from "../layout/AccessControl";
import AddNews from "../components/Blog/AddNews";

interface Props {
	userState: UserInterface;
}

export default function CreatePost({ userState }: Props) {
	const { t } = useTranslation("common");
	const { blogDispatch } = useContext(BlogContext);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[HOME_BLOG_WRITER, CAN_POST_MODIFY_HOME_BLOG]}>
			<h1>{t("news.__addNews__")}</h1>
			<div className="content-layout">
				{userState.userConnected.home_id ? (
					<AddNews userState={userState} blogDispatch={blogDispatch} />
				) : null}
			</div>
		</AccessControl>
	);
}
