import React from "react";
import VideoPlayer from "../components/Blog/MediaPlayer";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa";

export default function DisplayMedias({ medias }) {
	const { t } = useTranslation("common");
	const videoFiles = medias.filter(media => media.content_type.split("/")[0] === "video");
	const pdfFiles = medias.filter(media => media.content_type.split("/")[0] === "application");
	const picturesFiles = medias.filter(media => media.content_type.split("/")[0] === "image");

	const display2picts = picturesFiles => {
		const picturesToDisplay = picturesFiles.splice(0, 2);
		return (
			<div>
				{picturesToDisplay.map((pict, index) => (
					<div key={index} className="column">
						<img src={`/storages/blog_media/${pict.path}`} alt="illustration" className="pict" />
					</div>
				))}
			</div>
		);
	};

	return medias.length > 0 ? (
		<>
			{picturesFiles.length > 0 ? (
				picturesFiles.length === 1 ? (
					<img
						src={`/storages/blog_media/${picturesFiles[0].path}`}
						alt="illustration"
						className="pict"
					/>
				) : (
					display2picts(picturesFiles)
				)
			) : videoFiles.length > 0 ? (
				<VideoPlayer uriVideo={videoFiles[0].path} contentType={videoFiles[0].content_type} />
			) : null}

			{pdfFiles.length > 0 &&
				pdfFiles.map((elt, index) => (
					<div className="header-details" key={index}>
						<a href={`/storages/blog_media${elt.path}`} download={`${elt.filename}`}>
							<FaDownload className="mr-2" />
							<b>{elt.filename}</b> ({t("common.__pdfFile__")})
						</a>
					</div>
				))}
		</>
	) : null;
}
