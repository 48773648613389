import { postState } from "../utils/Blogging";
import { handleDate } from "@e4ia/e4link_modules.datetime";

const BlogReducer = (state, action) => {
	switch (action.type) {
		case "LOADING":
			return {
				...state,
				loading: true,
				error: false,
				success: false
			};

		case "GET_POSTS":
			return {
				...state,
				loading: false,
				news: action.payload,
				error: false
			};

		case "GET_POST":
			return {
				...state,
				loading: false,
				post: action.payload,
				error: false
			};

		case "ADD_POST": {
			const newPost = action.payload.post;
			const userLocale = action.payload.locale;
			const newPostCompleted = {
				...newPost,
				published: postState(newPost.accepted),
				eventDate: handleDate(newPost.date, "P", userLocale),
				videosCounter: newPost.medias.filter(media => media.content_type.split("/")[0] === "video")
					.length,
				pdfCounter: newPost.medias.filter(media => media.content_type === "application/pdf").length,
				photoCounter: newPost.medias.filter(media => media.content_type.split("/")[0] === "image")
					.length
			};
			return {
				...state,
				news: [newPostCompleted, ...state.news],
				loading: false,
				error: false
			};
		}

		case "EDIT_POST": {
			const updatedNews = action.payload.post;
			const userLocale = action.payload.locale;
			const newsUpdated = state.news.map(n => {
				if (n.id === updatedNews.id) {
					return {
						...updatedNews,
						published: postState(updatedNews.accepted),
						eventDate: handleDate(updatedNews.date, "P", userLocale),
						videosCounter: updatedNews.medias.filter(
							media => media.content_type.split("/")[0] === "video"
						).length,
						pdfCounter: updatedNews.medias.filter(media => media.content_type === "application/pdf")
							.length,
						photoCounter: updatedNews.medias.filter(media => media.content_type.split("/")[0] === "image")
							.length
					};
				}
				return n;
			});
			return {
				...state,
				news: newsUpdated,
				error: false,
				loading: false
			};
		}

		case "EDIT_POST_COUNTER": {
			const post = state.post;
			const postUpdated = { ...post, comment_counter: post.comment_counter + 1 };
			const newsUpdated = state.news.map(n => {
				if (n.id === action.payload) {
					return {
						...n,
						comment_counter: n.comment_counter + 1
					};
				}
				return n;
			});
			return {
				...state,
				post: postUpdated,
				news: newsUpdated,
				error: false,
				loading: false
			};
		}

		case "DELETE_POST":
			return {
				...state,
				news: state.news.filter(n => n.id !== action.payload),
				error: false,
				loading: false,
				success: true
			};

		case "ERROR":
			return {
				...state,
				error: true,
				loading: false
			};

		case "RESET_ES":
			return {
				...state,
				error: false,
				success: false
			};

		default:
			return state;
	}
};

export default BlogReducer;
