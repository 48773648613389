import React from "react";
import i18n from "../../i18n/i18next";
import { add, isAfter, isBefore } from "date-fns";
import { EventInterface } from "../../interfaces/EventInterface";
import VisioButton from "./VisioButton";

export const displayTiming = (dateTime: string) => {
	const time = dateTime.split(" ")[1];
	const timeToDisplay = time.split(":")[0] + ":" + time.split(":")[1];
	return timeToDisplay;
};

export const displayCategory = (type: number) => {
	switch (type) {
		case 2:
			return i18n.t("common:calendar.__event__");
		case 11:
			return i18n.t("common:calendar.__visio__");
		default:
			return i18n.t("common:calendar.__workshop__");
	}
};

export const hoursInDay = [
	0,
	1,
	2,
	3,
	4,
	5,
	6,
	7,
	8,
	9,
	10,
	11,
	12,
	13,
	14,
	15,
	16,
	17,
	18,
	19,
	20,
	21,
	22,
	23
];

export const displayStartVisioButton = (event: EventInterface, view: string) => {
	const visioDate = event.begin_datetime.split(" ").join("T");
	const visioStartDate = new Date(visioDate);
	const isTooEarly = isBefore(new Date(), visioStartDate);
	const timeToDisableVisioButton = add(visioStartDate, { hours: 1, minutes: 30 });
	const isTooLate = isAfter(new Date(), timeToDisableVisioButton);
	if (!isTooEarly && !isTooLate) {
		return <VisioButton visioData={event} view={view} />;
	} else {
		return null;
	}
};
