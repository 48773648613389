import React from "react";
import JitsiMeet from "./JitsiMeet";
import { useParams } from "react-router-dom";

export default function LiveMeeting() {
	const params = useParams();
	const subject = params.subject;
	const roomName = params.room;

	return (
		<>
			<h2>{subject}</h2>
			<JitsiMeet roomName={roomName} subject={subject} />
		</>
	);
}
