import React, { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { activityToSelect } from "../../constants/Activities";
import { HomeUnitsInterface, SeniorInterface } from "../../interfaces/UserStateInterface";
import Select from "react-select";
import HandleError from "../../layout/HandleError";
import http from "../../services/interceptor";
import * as url from "../../constants/Url";
import "./Occupation.scss";
import SeniorsSelectionContainer from "./SeniorsSelectionContainer";

interface AddAttendeesProps {
	homeId: number;
	userLocale: string;
	seniors: SeniorInterface[];
	selectedDate: Date;
	setSelectedDate: Function;
	event: any;
	homeUnits: HomeUnitsInterface[];
}

export default function AddAttendees({ homeId, seniors, event, homeUnits }: AddAttendeesProps) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const [activity, setActivity] = useState<number | null>(null);
	const [seniorsSelected, setSeniorsSelected] = useState<number[]>([]);
	const [error, setError] = useState<{ error: boolean; type: string | null }>({
		error: false,
		type: null
	});

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		if (activity) {
			setError({ error: false, type: null });
			const data = {
				occupation: {
					home_id: homeId,
					action_id: activity,
					datetime: event.begin_datetime,
					senior_ids: seniorsSelected,
					home_calendar_entry_id: event.id
				}
			};
			http
				.post(`${url.BASE_URL}/occupations`, data)
				.then(res => history.push(`/workshop/${event.id}/${res.data.occupation.id}`))
				.catch(() => setError({ error: true, type: "SERVEUR" }));
		} else {
			setError({ error: true, type: "ACTIVITY_MISSING" });
		}
	};

	return (
		<form onSubmit={onSubmit}>
			{error.error ? (
				<HandleError
					message={
						error.type === "ACTIVITY_MISSING"
							? t("common.__pleaseAddActivity__")
							: t("common.__errorOccured__")
					}
					type="error"
					setError={setError}
				/>
			) : null}
			<Select
				placeholder={t("calendar.__selectAnimation__")}
				name="activities"
				options={activityToSelect}
				className="basic-multi-select"
				classNamePrefix="select"
				onChange={e => (e?.value ? setActivity(e.value) : null)}
				isClearable={true}
			/>

			<SeniorsSelectionContainer
				seniors={seniors}
				seniorsSelected={seniorsSelected}
				setSeniorsSelected={setSeniorsSelected}
				homeUnits={homeUnits}
			/>
			<button
				type="button"
				className="btn btn-outline-secondary mt-3 mr-2"
				onClick={() => history.goBack()}>
				{t("common.__cancel__")}
			</button>
			<button type="submit" className="btn btn-primary mt-3">
				{t("common.__save__")}
			</button>
		</form>
	);
}
