import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { BlogContext } from "../context/BlogContext";
import { UserInterface } from "../interfaces/UserStateInterface";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";
import http from "../services/interceptor";
import SinglePost from "../components/Blog/SinglePost";
import BlogHeaderNav from "../layout/BlogHeaderNav";

interface PostProps {
	userState: UserInterface;
}

export default function Post({ userState }: PostProps) {
	const [familyLikes, setFamilyLikes] = useState([]);
	const { id } = useParams<{ id?: string }>();
	const { blogStore, blogDispatch } = useContext(BlogContext);
	const isBlogger = userState.userConnected.is(p.HOME_BLOG_WRITER);
	const isModerator = userState.userConnected.is(p.CAN_MODERATE_HOME_BLOG);
	const canRemovePost = userState.userConnected.is(p.CAN_POST_MODIFY_HOME_BLOG);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
		if (id) {
			http
				.get(`${url.HOMES_URL}/blog_posts/${id}`)
				.then(res => {
					blogDispatch({
						type: "GET_POST",
						payload: res.data.blog_post
					});
				})
				.catch(() => {
					blogDispatch({
						type: "GET_POST",
						payload: {}
					});
				});
			http
				.get(`${url.HOMES_URL}/blog_posts/${id}/who/like`)
				.then(res => setFamilyLikes(res.data.family_users))
				.catch(() => setFamilyLikes([]));
		}
	}, [id]);

	return (
		<>
			<BlogHeaderNav title={" "} />
			<div className="content-layout">
				<SinglePost
					post={blogStore.post}
					userState={userState}
					familyLikes={familyLikes}
					isBlogger={isBlogger}
					isModerator={isModerator}
					canRemovePost={canRemovePost}
					blogDispatch={blogDispatch}
				/>
			</div>
		</>
	);
}
