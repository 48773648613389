import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
	format,
	startOfWeek,
	addDays,
	startOfMonth,
	endOfMonth,
	endOfWeek,
	isSameMonth,
	isSameDay
} from "date-fns";
import { BsCalendarPlus } from "react-icons/bs";
import { EventInterface } from "../../interfaces/EventInterface";
import { SeniorInterface } from "../../interfaces/UserStateInterface";
import EventDetailModal from "./EventDetailModal";
import HandleLocale from "../../utils/HandleLocale";
import Loader from "../../layout/Loader";
import EventScheduled from "./EventScheduled";
import "./Calendar.scss";

interface Props {
	userLocale: string;
	eventsList: EventInterface[];
	selectedDate: Date;
	isLoading: boolean;
	canManageCalendar: boolean;
	seniors: SeniorInterface[];
	show: { show: boolean; event: EventInterface | null; success: boolean };
	setShow: Function;
	setSelectedDate: Function;
}

const CalendarMonthView = ({
	userLocale,
	eventsList,
	selectedDate,
	setSelectedDate,
	isLoading,
	canManageCalendar,
	seniors,
	show,
	setShow
}: Props) => {
	const history = useHistory();
	const locale = HandleLocale(userLocale);

	const getWeekDaysNames = () => {
		const weekStartDate = startOfWeek(selectedDate, { weekStartsOn: 1 });
		const weekDays = [0, 1, 2, 3, 4, 5, 6];
		return (
			<div className="monthContainer">
				{weekDays.map(day => (
					<div className="weekNames" key={day}>
						{format(addDays(weekStartDate, day), "E", { locale: locale })}
					</div>
				))}
			</div>
		);
	};

	const handleEvent = (currentDate: Date) => {
		const dateToCompare = format(currentDate, "yyyy-MM-dd");
		const eventsToDisplay = eventsList.filter(
			(event: EventInterface) => event.eventDate === dateToCompare
		);
		if (eventsToDisplay.length > 0) {
			return eventsToDisplay.map((event: EventInterface) => (
				<EventScheduled
					event={event}
					setShow={setShow}
					key={event.id}
					view={"month"}
					seniors={seniors}
				/>
			));
		} else {
			return canManageCalendar ? (
				<BsCalendarPlus
					className="add-event-icon ml-2"
					onClick={() => handleAddEvent(currentDate)}
					role="button"
				/>
			) : null;
		}
	};

	const handleAddEvent = (date: Date) => {
		setSelectedDate(date);
		history.push("/addevent");
	};

	const handleDayView = (date: Date) => {
		setSelectedDate(date);
		history.push("/calenday");
	};

	const generateDatesForCurrentWeek = (currentDate: Date, activeDate: Date) => {
		const week = [];
		for (let day = 0; day < 7; day++) {
			const dateToCompare = currentDate;
			week.push(
				<div className="month-card" key={day}>
					<Link to={`/calenday`}>
						<div
							role="button"
							onClick={() => handleDayView(dateToCompare)}
							className={`
								${isSameMonth(currentDate, activeDate) ? "day" : "inactiveDay"}
								${isSameDay(currentDate, selectedDate) ? "selectedDay" : "day"}
								${isSameDay(currentDate, new Date()) ? "today" : "day"}
							`}>
							{format(currentDate, "d")}
						</div>
					</Link>
					{handleEvent(currentDate)}
				</div>
			);
			currentDate = addDays(currentDate, 1);
		}
		return <>{week}</>;
	};

	const getDates = () => {
		const startOfTheSelectedMonth = startOfMonth(selectedDate);
		const endOfTheSelectedMonth = endOfMonth(selectedDate);
		const startDate = startOfWeek(startOfTheSelectedMonth, { weekStartsOn: 1 });
		const endDate = endOfWeek(endOfTheSelectedMonth, { weekStartsOn: 1 });

		let currentDate = startDate;
		const allWeeks = [];

		while (currentDate <= endDate) {
			allWeeks.push(generateDatesForCurrentWeek(currentDate, selectedDate));
			currentDate = addDays(currentDate, 7);
		}
		return <div className="monthContainer">{allWeeks}</div>;
	};

	return isLoading ? (
		<Loader />
	) : (
		<>
			{getWeekDaysNames()}
			{getDates()}
			<EventDetailModal show={show} setShow={setShow} userLocale={userLocale} seniors={seniors} />
		</>
	);
};

export default CalendarMonthView;
