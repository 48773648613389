import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../i18n/LanguageSwitcher";
import http from "../../services/interceptor";
import * as url from "../../constants/Url";
import HandleError from "../../layout/HandleError";

interface EditMyAccountProps {
	userData: {
		id: number;
		first_name: string;
		last_name: string;
		email: string;
		phone: string;
		mobile_phone: string;
		locale: string;
		login: string;
	};
	setEditMyAccount: Function;
	setUpdate: Function;
}

export default function EditMyAccount({
	userData,
	setEditMyAccount,
	setUpdate
}: EditMyAccountProps) {
	const { t } = useTranslation("common");
	const [user, setUser] = useState(userData);
	const [userLocale, setUserLocale] = useState(userData.locale);
	const [error, setError] = useState(false);

	const languages = [
		{
			id: 1,
			name: t("users.__french__"),
			locale: "fr"
		},
		{
			id: 2,
			name: t("users.__english__"),
			locale: "en"
		},
		{
			id: 3,
			name: t("users.__dutch__"),
			locale: "nl"
		}
	];

	const onSubmit = (e: any) => {
		e.preventDefault();
		setError(false);
		const data = { user: { ...user, locale: userLocale } };

		http
			.put(`${url.BASE_URL}/me`, data)
			.then(res => {
				localStorage.setItem("locale", JSON.stringify(res.data.user.locale));
				if (res.data.user.locale === "fr") {
					localStorage.setItem("i18next", "fr-FR");
				} else if (res.data.user.locale === "nl") {
					localStorage.setItem("i18next", "nl-BE");
				} else {
					localStorage.setItem("i18next", "en-EN");
				}
				setEditMyAccount(false);
				setUpdate(true);
			})
			.catch(() => setError(true));
	};

	return (
		<>
			{error ? (
				<HandleError message={t("common.__errorOccured__")} type="error" setError={setError} />
			) : null}
			<form onSubmit={onSubmit}>
				<div className="row mt-5">
					<div className="col-6">{t("common.__login__")}</div>
					<div className="col-6">
						<input
							type="text"
							aria-describedby="titleHelp"
							className="form-control"
							onChange={e => setUser({ ...user, login: e.target.value })}
							defaultValue={user?.login}
						/>
						<small id="titlelHelp" className="form-text text-muted right">
							{t("common.__required__")}
						</small>
					</div>
					<div className="col-6">{t("common.__firstname__")}</div>
					<div className="col-6">
						<input
							type="text"
							aria-describedby="titleHelp"
							className="form-control"
							onChange={e => setUser({ ...user, first_name: e.target.value })}
							defaultValue={user?.first_name}
						/>
						<small id="titlelHelp" className="form-text text-muted right">
							{t("common.__required__")}
						</small>
					</div>
					<div className="col-6">{t("common.__name__")}</div>
					<div className="col-6">
						<input
							type="text"
							aria-describedby="titleHelp"
							className="form-control"
							onChange={e => setUser({ ...user, last_name: e.target.value })}
							defaultValue={user?.last_name}
						/>
						<small id="titlelHelp" className="form-text text-muted right">
							{t("common.__required__")}
						</small>
					</div>
					<div className="col-6">{t("common.__mail__")}</div>
					<div className="col-6">
						<input
							type="text"
							aria-describedby="titleHelp"
							className="form-control"
							onChange={e => setUser({ ...user, email: e.target.value })}
							defaultValue={user?.email}
						/>
						<small id="titlelHelp" className="form-text text-muted right">
							{t("common.__required__")}
						</small>
					</div>
					<div className="col-6">{t("common.__phone__")}</div>
					<div className="col-6">
						<input
							type="text"
							aria-describedby="titleHelp"
							className="form-control mb-3"
							onChange={e => setUser({ ...user, phone: e.target.value })}
							defaultValue={user?.phone}
						/>
					</div>
					<div className="col-6">{t("common.__smartphone__")}</div>
					<div className="col-6">
						<input
							type="text"
							aria-describedby="titleHelp"
							className="form-control mb-3"
							onChange={e => setUser({ ...user, mobile_phone: e.target.value })}
							defaultValue={user?.mobile_phone}
						/>
					</div>
					<div className="col-6">{t("common.__language__")}</div>
					<div className="col-6">
						<LanguageSwitcher
							userLocale={userLocale}
							setUserLocale={setUserLocale}
							languages={languages}
						/>
					</div>
					<div className="col-12 mt-4">
						<button
							className="btn btn-sm btn-outline-secondary mr-2"
							type="button"
							onClick={() => setEditMyAccount(false)}>
							{t("common.__back__")}
						</button>
						<button className="btn btn-sm btn-primary" type="submit">
							{t("common.__save__")}
						</button>
					</div>
				</div>
			</form>
		</>
	);
}
