import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useHistory } from "react-router-dom";
import { SeniorInterface } from "../interfaces/UserStateInterface";
import * as url from "../constants/Url";
import http from "../services/interceptor";
import { getActivityAction } from "../constants/Activities";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { BsArrowRepeat, BsChevronRight, BsFillPinAngleFill, BsPencilSquare } from "react-icons/bs";
import { EventInterface, OccupationInterface } from "../interfaces/EventInterface";
import { displayCategory } from "../components/Calendar/CalendarUtils";
import EventDetailModal from "../components/Calendar/EventDetailModal";

interface OccupationDetailsProps {
	seniors: SeniorInterface[];
}

export default function OccupationDetails({ seniors }: OccupationDetailsProps) {
	const { t } = useTranslation("common");
	const { eventId } = useParams<{ eventId?: string }>();
	const history = useHistory();
	const { occupationId } = useParams<{ occupationId?: string }>();
	const [occupation, setOccupation] = useState<OccupationInterface | null>(null);
	const [event, setEvent] = useState<EventInterface | null>(null);

	useEffect(() => {
		if (eventId) {
			http
				.get(`${url.BASE_URL}/home_calendar_entries/${eventId}`)
				.then(res => setEvent(res.data.calendar_entry))
				.catch(() => setOccupation(null));
		}
	}, [eventId]);

	useEffect(() => {
		http
			.get(`${url.BASE_URL}/occupations/${occupationId}`)
			.then(res => {
				const result = res.data.occupation;
				const seniorIds = result.senior_ids;
				const attendees = seniors.filter((senior: SeniorInterface) => seniorIds.includes(senior.id));
				const occupationWithSeniors = {
					...result,
					seniors: attendees,
					activity: getActivityAction(result.action_id)
				};
				setOccupation(occupationWithSeniors);
			})
			.catch(() => setOccupation(null));
	}, [occupationId]);

	return (
		<>
			<div className="mr-2 d-flex justify-content-end">
				<button type="button" onClick={() => history.goBack()} className={`btn btn-outline-primary`}>
					{t("common.__back__")}
				</button>
			</div>
			{occupation ? (
				<div className="content-layout">
					<div className="d-flex justify-content-between">
						<div>
							<h2>
								<BsFillPinAngleFill className="mr-2" />
								{occupation.activity}
							</h2>
							<div className="separator"></div>
							<p className="meta">
								{t("common.__date__")} : {handleDate(occupation.datetime, "PPPp")}
							</p>
						</div>
						{eventId && occupation ? (
							<Link to={`/occupation/${eventId}/${occupation.id}`} className="mt-2">
								<BsPencilSquare role="button" className="big-kpi-icon regular" />
							</Link>
						) : null}
					</div>

					{occupation.seniors.length > 0 ? (
						<>
							<p className="mt-3">{t("calendar.__seniorsIn__")} :</p>
							{occupation.seniors.map((senior: SeniorInterface) => (
								<span className="senior-badge mr-2" style={{ color: "#fff" }} key={senior.id}>
									{senior.name}
								</span>
							))}
						</>
					) : (
						<p className="meta">{t("calendar.__noAttendees__")}</p>
					)}

					{event ? (
						<div className="header-details d-flex justify-content-between mt-4 pt-4">
							<div className="d-flex">
								<p
									className={
										event.entry_type_id === 1
											? "badge badge-pill badge-primary mr-3"
											: "badge badge-pill badge-danger mr-3"
									}>
									{displayCategory(event.entry_type_id)}
								</p>
								<div>
									<p>
										{event.info}
										{event.detail ? (
											<>
												<BsChevronRight className="mr-2 ml-2" /> {event.detail}
											</>
										) : null}
									</p>
								</div>
							</div>
							{event.recurring_entry_id ? <BsArrowRepeat className="big-kpi-icon" /> : null}
						</div>
					) : null}
				</div>
			) : (
				<p>{t("common.__noData__")}</p>
			)}
		</>
	);
}
