import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { format, addDays, startOfWeek } from "date-fns";
import { BsCalendarPlus } from "react-icons/bs";
import { hoursInDay } from "./CalendarUtils";
import { EventInterface } from "../../interfaces/EventInterface";
import { SeniorInterface } from "../../interfaces/UserStateInterface";
import Loader from "../../layout/Loader";
import EventDetailModal from "./EventDetailModal";
import CalendarWeekViewContainer from "./CalendarWeekViewContainer";

interface Props {
	eventsList: EventInterface[];
	isLoading: boolean;
	selectedDate: Date;
	setSelectedDate: Function;
	locale: any;
	canManageCalendar: boolean;
	seniors: SeniorInterface[];
	show: { show: boolean; event: EventInterface | null; success: boolean };
	setShow: Function;
}

export default function CalendarWeekView({
	eventsList,
	isLoading,
	selectedDate,
	setSelectedDate,
	locale,
	canManageCalendar,
	seniors,
	show,
	setShow
}: Props) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref && ref.current) {
			ref.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [ref.current]);

	const handleDay = (date: Date) => {
		setSelectedDate(date);
		history.push("/calenday");
	};

	const getSelectedWeekDaysNames = () => {
		const weekStartDate = startOfWeek(selectedDate, { weekStartsOn: 1 });
		const weekDays = [0, 1, 2, 3, 4, 5, 6];
		return (
			<div className="weekContainer">
				{weekDays.map(day => (
					<div className="weekNames" key={day}>
						{format(addDays(weekStartDate, day), "E", { locale: locale })}
						<div
							onClick={() => handleDay(addDays(weekStartDate, day))}
							role="button"
							className="weekview-day">
							{format(addDays(weekStartDate, day), "dd", { locale: locale })}
						</div>
					</div>
				))}
			</div>
		);
	};

	return isLoading ? (
		<Loader />
	) : (
		<>
			{eventsList.length > 0 ? (
				<>
					<div className="d-flex">
						<div style={{ width: "4vw", marginRight: "0.5rem" }}></div>
						<div style={{ width: "95vw" }}>{getSelectedWeekDaysNames()}</div>
					</div>
					<div className="calenday-container">
						{hoursInDay.map(hour => (
							<div className="d-flex" key={hour} ref={hour === 8 ? ref : null}>
								<div className="calenday-hours" style={{ width: "4vw", marginRight: "0.5rem" }}>
									<p>{hour}:00</p>
								</div>
								<div style={{ width: "95vw" }}>
									<CalendarWeekViewContainer
										eventsList={eventsList}
										setShow={setShow}
										selectedDate={selectedDate}
										hour={`${hour}`}
										canManageCalendar={canManageCalendar}
										setSelectedDate={setSelectedDate}
										seniors={seniors}
									/>
								</div>
							</div>
						))}
					</div>
				</>
			) : (
				<>
					<p className="meta mt-3">{t("calendar.__noAppointment__")}</p>
					{canManageCalendar ? (
						<button
							type="button"
							className="btn btn-outline-primary"
							onClick={() => history.push(`/addevent`)}>
							<BsCalendarPlus className="mr-2" />
							{t("postsNavigation.__create__")}
						</button>
					) : null}
				</>
			)}
			<EventDetailModal show={show} setShow={setShow} userLocale={locale} seniors={seniors} />
		</>
	);
}
