import React from "react";
import { useTranslation } from "react-i18next";
import { BsX } from "react-icons/bs";

interface Props {
	message: string;
	type: string;
	setError?: Function;
	dispatch?: Function;
}

export default function HandleError({ message, type, dispatch, setError }: Props) {
	const { t } = useTranslation("common");

	const hideError = () => {
		if (type === "error" && setError) {
			setError(false);
		} else if (type === "dispatch" && dispatch) {
			dispatch({ type: "RESET_ES" });
		} else {
			return;
		}
	};

	return (
		<div className="error">
			<div className="d-flex justify-content-between">
				<h5>{message ? message : t("common.__errorOccured__")}</h5>

				<div className="right" onClick={hideError}>
					<BsX className="big-kpi-icon" style={{ marginTop: "0.35rem" }} />
				</div>
			</div>
		</div>
	);
}
