import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { UserInterface } from "../interfaces/UserStateInterface";
import { CAN_MANAGE_HOME_CALENDAR } from "../constants/Permissions";
import AccessControl from "../layout/AccessControl";
import AddEventForm from "../components/Calendar/AddEventForm";

interface Props {
	userState: UserInterface;
	selectedDate: Date;
	setSelectedDate: Function;
}

export default function CalendarAddEvent({ userState, selectedDate, setSelectedDate }: Props) {
	const { t } = useTranslation("common");
	const history = useHistory();

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[CAN_MANAGE_HOME_CALENDAR]}>
			<>
				<div className="row">
					<div className="col-6">
						<h1>{t("calendar.__addAnimation__")}</h1>
					</div>
					<div className="col-6 ">
						<button
							type="button"
							className="btn btn-outline-secondary float-right mt-2"
							onClick={() => history.goBack()}>
							{t("common.__back__")}
						</button>
					</div>
				</div>
				<div className="content-layout">
					<AddEventForm
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						userLocale={userState.userConnected.locale}
						homeId={userState.userConnected.home_id}
					/>
				</div>
			</>
		</AccessControl>
	);
}
