import React, { useState, useEffect } from "react";
import { isSameDay } from "date-fns";
import { useTranslation } from "react-i18next";
import { FaVideo } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as url from "../../constants/Url";
import http from "../../services/interceptor";

interface VisioButtonProps {
	visioData: any;
	view: string;
}

export default function VisioButton({ visioData, view }: VisioButtonProps) {
	const { t } = useTranslation("common");
	const visioScheduled = new Date(visioData.begin_datetime.split(" ").join("T"));
	const isVisioScheduledToday = isSameDay(visioScheduled, new Date());

	const [visioTime, setVisioTime] = useState<{
		subject: string;
		room: string;
		visioDate: Date;
		datetime: string;
		id: number;
	} | null>(null);

	useEffect(() => {
		if (isVisioScheduledToday && visioData) {
			if (visioData.id) {
				http
					.get(`${url.HOMES_URL}/controlpanel/visio/${visioData.id}`)
					.then(res => {
						const result = res.data;
						const visio = { ...result, visioDate: new Date(result.datetime.split(" ").join("T")) };
						setVisioTime(visio);
					})
					.catch(() => setVisioTime(null));
			}
		}
	}, [visioData, isVisioScheduledToday]);

	return isVisioScheduledToday && visioTime ? (
		<Link to={`/livemeeting/${visioTime.subject}/${visioTime.room}`}>
			<button type="button" className="btn btn-danger mt-2 mb-2">
				<FaVideo className="mr-2" />
				<b>{view === "day" ? t("calendar.__startVisio__") : t("calendar.__start__")}</b>
			</button>
		</Link>
	) : null;
}
