import React from "react";
import { useTranslation } from "react-i18next";
import { BsX } from "react-icons/bs";

interface Props {
	message?: string;
	type: string;
	setSuccess?: Function;
	dispatch?: Function;
}

export default function HandleSuccess({ message, type, dispatch, setSuccess }: Props) {
	const { t } = useTranslation("common");

	const hidesetSuccessMessage = () => {
		if (type === "success" && setSuccess) {
			setSuccess(false);
		} else if (type === "dispatch" && dispatch) {
			dispatch({ type: "RESET_ES" });
		} else {
			return;
		}
	};

	return (
		<div className="success">
			<div className="d-flex justify-content-between">
				<h5>{message ? message : t("common.__addSuccessMsg__")}</h5>

				<div className="right" onClick={hidesetSuccessMessage}>
					<BsX className="big-kpi-icon" style={{ marginTop: "0.35rem" }} />
				</div>
			</div>
		</div>
	);
}
