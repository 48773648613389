import React from "react";
import i18next from "./i18next";
import { withTranslation } from "react-i18next";

function LanguageSwitcher({ userLocale, setUserLocale, languages }) {
	const changeLanguage = lng => {
		i18next.changeLanguage(lng);
		setUserLocale(lng);
	};

	return (
		<select className="form-control form-control-sm" onChange={e => changeLanguage(e.target.value)} defaultValue={userLocale}>
			{languages.map(language => (
				<option key={language.id} value={language.locale}>
					{language.name}
				</option>
			))}
		</select>
	);
}

export default withTranslation()(LanguageSwitcher);
