import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EditMyAccount from "./EditMyAccount";

interface MyAccountProps {
	user: {
		id: number;
		first_name: string;
		last_name: string;
		email: string;
		phone: string;
		mobile_phone: string;
		locale: string;
		login: string;
	};
	setUpdate: Function;
}

export default function MyAccount({ user, setUpdate }: MyAccountProps) {
	const { t } = useTranslation("common");
	const [editMyAccount, setEditMyAccount] = useState(false);

	const displayLanguage = (locale: string) => {
		switch (locale) {
			case "fr":
				return t("users.__french__");
			case "en":
				return t("users.__english__");
			case "nl":
				return t("users.__dutch__");
			default:
				return;
		}
	};

	return user ? (
		editMyAccount ? (
			<EditMyAccount userData={user} setEditMyAccount={setEditMyAccount} setUpdate={setUpdate} />
		) : (
			<div className="row">
				<div className="col-12">
					<br />
				</div>
				<div className="col-6">
					<h2 className="mt-2">
						{user.first_name} {user.last_name}
					</h2>
				</div>
				<div className="col-6">
					<button
						className="btn btn-sm btn-outline-primary right"
						type="button"
						onClick={() => setEditMyAccount(true)}>
						{t("common.__update__")}
					</button>
				</div>
				<div className="col-12">
					<p>
						{t("common.__mail__")} : <strong>{user.email}</strong>
					</p>
					<p>
						{t("common.__phone__")} : {user.phone}
					</p>
					<p>
						{t("common.__smartphone__")} : {user.mobile_phone}
					</p>
					<p>
						{t("common.__language__")} : {displayLanguage(user.locale)}
					</p>
				</div>
			</div>
		)
	) : null;
}
