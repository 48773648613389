import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Loader from "../../layout/Loader";

const JitsiMeet = ({ roomName, subject }) => {
	const { t } = useTranslation("common");
	const domain = "dev03.e4-ia.tech";
	const [jitsi, setJitsi] = useState(null);
	const [endVisio, setEndVisio] = useState(false);
	const [loading, setLoading] = useState(true);
	const history = useHistory();

	const options = {
		roomName: roomName,
		subject,
		parentNode: "jitsi-container",
		configOverwrite: {
			disableSimulcast: false,
			defaultLanguage: "fr"
		}
	};

	const containerStyle = {
		width: "100%",
		height: "90vh"
	};

	const jitsiContainerStyle = {
		display: loading ? "none" : "block",
		width: "100%",
		height: "100%"
	};

	useEffect(() => {
		if (window.JitsiMeetExternalAPI) {
			options.parentNode = document.getElementById(options.parentNode);
			// eslint-disable-next-line no-undef
			setJitsi(new JitsiMeetExternalAPI(domain, options));
		} else {
			setJitsi({ error: t("calendar.__visioNotAvailable__") });
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (jitsi) {
			setLoading(false);
			jitsi.addEventListener("readyToClose", () => {
				setEndVisio(true);
				jitsi.dispose();
			});
		}
	}, [jitsi]);

	/// onClose we redirect the user to his visioDashboard
	useEffect(() => {
		if (endVisio) {
			history.push("/calendar");
		}
		// eslint-disable-next-line
	}, [endVisio]);

	return (
		<div className="live-meeting">
			<div style={containerStyle}>
				{loading && <Loader />}
				<div id="jitsi-container" style={jitsiContainerStyle} />
			</div>
		</div>
	);
};

export default JitsiMeet;
