import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { EventInterface } from "../../interfaces/EventInterface";
import { hoursInDay } from "./CalendarUtils";
import { useHistory } from "react-router-dom";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { BsCalendarPlus } from "react-icons/bs";
import EventScheduled from "./EventScheduled";
import { SeniorInterface } from "../../interfaces/UserStateInterface";

interface Props {
	eventsList: EventInterface[];
	setShow: Function;
	canManageCalendar: boolean;
	selectedDate: Date;
	setSelectedDate: Function;
	seniors: SeniorInterface[];
}

const CalendarDayViewContainer = ({
	eventsList,
	setShow,
	selectedDate,
	setSelectedDate,
	canManageCalendar,
	seniors
}: Props) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref && ref.current) {
			ref.current.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	const redirectToAddEvent = (hour: string) => {
		const date = handleDate(selectedDate, `yyyy-MM-dd ${hour}:00:00`);
		setSelectedDate(new Date(date));
		history.push("/addevent");
	};

	const handleEvent = (hour: string) => {
		const hourToCompare = hour.length === 1 ? `0${hour}` : hour;
		const eventsToDisplayForThisHour = eventsList.filter(
			(event: EventInterface) => event.eventTiming.split(":")[0] === hourToCompare
		);
		if (eventsToDisplayForThisHour.length > 0) {
			return eventsToDisplayForThisHour.map((event: EventInterface) => (
				<EventScheduled
					event={event}
					setShow={setShow}
					key={event.id}
					view={"day"}
					setSelectedDate={setSelectedDate}
					seniors={seniors}
				/>
			));
		} else {
			return canManageCalendar ? (
				<div className="add-event" onClick={() => redirectToAddEvent(hour)}>
					<BsCalendarPlus className="mr-2" />
					{t("postsNavigation.__create__")}
				</div>
			) : null;
		}
	};

	return (
		<>
			{hoursInDay.map(hour => (
				<div className="d-flex" key={hour}>
					<div ref={hour === 8 ? ref : null} className="calenday-hours">
						<p>{hour}:00</p>
					</div>
					<div className="calenday-events">{handleEvent(`${hour}`)}</div>
				</div>
			))}
		</>
	);
};

export default CalendarDayViewContainer;
