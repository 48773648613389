import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BsX } from "react-icons/bs";
import http from "../../../services/interceptor";
import * as url from "../../../constants/Url";
import HandleError from "../../../layout/HandleError";

interface RemovePostProps {
	show: { postId: number | null; postTitle: string | null };
	setShow: Function;
	blogDispatch: Function;
	redirection: boolean;
}

export const RemovePostModal = ({ show, setShow, blogDispatch, redirection }: RemovePostProps) => {
	const { t } = useTranslation("common");
	const [error, setError] = useState(false);
	const history = useHistory();

	const removePost = (id: number | null) => {
		if (id) {
			setError(false);
			http
				.delete(`${url.HOMES_URL}/blog_posts/${id}`)
				.then(() => {
					setShow({ show: false, postId: null, postTitle: " " });
					blogDispatch({
						type: "DELETE_POST",
						payload: id
					});
					if (redirection) {
						history.push("/myposts");
					}
				})
				.catch(() => setError(true));
		}
	};

	const closeModal = () => {
		setError(false);
		setShow({ show: false, postId: null, postTitle: null });
	};

	return show.postId ? (
		<div className="overlay">
			<div className="dialog px-4 py-4">
				<div
					className="btn btn-sm btn-outline-secondary right"
					role="button"
					onClick={() => closeModal()}>
					<BsX />
				</div>
				<h2>{t("news.__removePostWarningTitle__")}</h2>
				<div className="separator"></div>
				{error ? (
					<HandleError message={t("common.__delError__")} type="error" setError={setError} />
				) : null}
				{show.postTitle ? (
					<p className="header-details">
						{t("news.__postSelected__")} : <b>{show.postTitle}</b>
					</p>
				) : null}

				<p className="mt-3 red">
					<b>{t("news.__removePostWarning__")}</b>
				</p>
				<div className="center mt-4">
					<button
						type="button"
						className="btn btn-sm btn-outline-secondary mr-2"
						onClick={() => closeModal()}>
						{t("common.__cancel__")}
					</button>
					<button
						type="button"
						className="btn btn-sm btn-danger"
						onClick={() => removePost(show.postId)}>
						{t("calendar.__remove__")}
					</button>
				</div>
			</div>
		</div>
	) : null;
};
