import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import http from "../../services/interceptor";
import * as url from "../../constants/Url";
import HandleError from "../../layout/HandleError";

interface LostPasswordProps {
	displayTitle: boolean;
}

const LostPassword = ({ displayTitle }: LostPasswordProps) => {
	const { t } = useTranslation("common");
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState(false);
	const [msg, setMsg] = useState(false);

	const onSubmit = (e: any) => {
		e.preventDefault();
		setEmailError(false);

		if (/^\S+@\S+\.\S+$/.test(email)) {
			const data = {
				email: email
			};
			http
				.post(`${url.BASE_URL}/lost_password`, data)
				.then(() => {
					setMsg(true);
				})
				.catch(() => {
					setEmailError(true);
				});
		} else {
			setEmailError(true);
		}
	};

	return (
		<div className="login-container mx-auto">
			{displayTitle ? <h2>{t("users.__forgotPassword__")}</h2> : null}

			{emailError ? (
				<HandleError
					message={t("users.__forgotPasswordError__")}
					type="error"
					setError={setEmailError}
				/>
			) : (
				<br />
			)}

			{msg ? (
				<div className="success">
					<h5>
						<b>{t("users.__forgotPasswordSucces__")}</b>
					</h5>
				</div>
			) : (
				<form onSubmit={onSubmit}>
					<div className="form-group">
						<label htmlFor="email">{t("common.__mail__")}</label>
						<input
							type="text"
							id="email"
							className="form-control"
							onChange={e => setEmail(e.target.value)}
							required
						/>
						<small id="emailHelp" className="form-text text-muted right">
							{t("common.__required__")}
						</small>
					</div>
					<button type="submit" className="btn btn-primary mt-3">
						{t("common.__validate__")}
					</button>
				</form>
			)}
		</div>
	);
};

export default LostPassword;
