import React, { useEffect, useState } from "react";
import { getActivityAction } from "../../../constants/Activities";
import { FaRegClock, FaUsersCog } from "react-icons/fa";
import { BsCalendar3 } from "react-icons/bs";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { SeniorInterface } from "../../../interfaces/UserStateInterface";
import { OccupationInterface, EventInterface } from "../../../interfaces/EventInterface";
import { BsClipboardCheck } from "react-icons/bs";
import http from "../../../services/interceptor";
import * as url from "../../../constants/Url";
import "./News.scss";
import { Link } from "react-router-dom";

interface Props {
	occupation: OccupationInterface;
	seniors: SeniorInterface[];
}

export default function OccupationContainer({ occupation, seniors }: Props) {
	const [event, setEvent] = useState<EventInterface | null>(null);
	const [displayDetails, setDisplayDetails] = useState<{ show: boolean; id: number | null }>({
		show: false,
		id: null
	});

	const displaySeniorName = (seniorId: number): JSX.Element | null => {
		const senior = seniors.find(senior => senior.id === seniorId);
		return senior ? <span className="senior-badge mr-2 mt-1">{senior.name}</span> : null;
	};

	useEffect(() => {
		if (occupation.home_calendar_entry_id) {
			http
				.get(`${url.BASE_URL}/home_calendar_entries/${occupation.home_calendar_entry_id}`)
				.then(res => setEvent(res.data.calendar_entry));
		}
	}, [occupation.home_calendar_entry_id]);

	return (
		<>
			<div className="d-flex justify-content-between occupation-flow">
				<Link to={`/workshop/${occupation.home_calendar_entry_id}/${occupation.id}`}>
					<p className="occupation-title">
						<BsCalendar3 className="mr-2" />
						{getActivityAction(occupation.action_id)}
					</p>
				</Link>
				{occupation.senior_ids.length > 0 ? (
					<div
						className="occupation-toggle"
						role="button"
						onClick={() =>
							setDisplayDetails({ show: !displayDetails.show, id: occupation.home_calendar_entry_id })
						}>
						<FaUsersCog className="nav-icon mr-2" />
						{occupation.senior_ids.length}
					</div>
				) : null}
			</div>
			<div className="occupation-block">
				<p className="occupation-meta">
					<FaRegClock className="nav-icon mr-2" />
					{handleDate(occupation.datetime, "Pp")}
					{event ? (
						<span className="ml-3">
							<strong>
								<BsClipboardCheck className="nav-icon mr-2" />
								{event.info}
							</strong>
							{event.detail ? <> : {event.detail}</> : null}
						</span>
					) : null}
				</p>
				{displayDetails.show && displayDetails.id === occupation.home_calendar_entry_id ? (
					<div className="mt-3 mb-3">{occupation.senior_ids.map(id => displaySeniorName(id))}</div>
				) : null}
			</div>
		</>
	);
}
