import i18n from "../i18n/i18next";

export const WORKSHOP = 1;
export const EVENT = 2;
export const VISIO = 3;

export const SINGLE = 0;
export const EVERY_DAYS = 1;
export const EVERY_WORKING_DAYS = 2;
export const SELECTED_DAYS = 3;
export const EVERY_MONTH = 4;

export function eventTypes(type: number) {
	switch (type) {
		case 2:
			return i18n.t("common:calendar.__event__");
		case 11:
			return i18n.t("common:calendar.__visio__");
		default:
			return i18n.t("common:calendar.__workshop__");
	}
}

export const selectedDays = [
	{
		id: 1,
		name: i18n.t("common:common.__monday__")
	},
	{
		id: 2,
		name: i18n.t("common:common.__tuesday__")
	},
	{
		id: 3,
		name: i18n.t("common:common.__wednesday__")
	},
	{
		id: 4,
		name: i18n.t("common:common.__thursday__")
	},
	{
		id: 5,
		name: i18n.t("common:common.__friday__")
	},
	{
		id: 6,
		name: i18n.t("common:common.__saturday__")
	},
	{
		id: 7,
		name: i18n.t("common:common.__sunday__")
	}
];

export const displayDay = (day: number) => {
	switch (day) {
		case 1:
			return i18n.t("common:common.__monday__");
		case 2:
			return i18n.t("common:common.__tuesday__");
		case 3:
			return i18n.t("common:common.__wednesday__");
		case 4:
			return i18n.t("common:common.__thursday__");
		case 5:
			return i18n.t("common:common.__friday__");
		case 6:
			return i18n.t("common:common.__saturday__");
		case 7:
			return i18n.t("common:common.__sunday__");
		default:
			return;
	}
};
