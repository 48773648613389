import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { addDays, endOfWeek, format, startOfWeek } from "date-fns";
import { BsCalendarPlus } from "react-icons/bs";
import { EventInterface } from "../../interfaces/EventInterface";
import EventScheduled from "./EventScheduled";
import "./Calendar.scss";
import { SeniorInterface } from "../../interfaces/UserStateInterface";

interface Props {
	eventsList: EventInterface[];
	setShow: Function;
	selectedDate: Date;
	hour: string;
	canManageCalendar: boolean;
	setSelectedDate: Function;
	seniors: SeniorInterface[];
}

const CalendarWeekViewContainer = ({
	eventsList,
	setShow,
	selectedDate,
	hour,
	canManageCalendar,
	setSelectedDate,
	seniors
}: Props) => {
	const { t } = useTranslation("common");
	const history = useHistory();

	const handleAddEvent = (date: Date) => {
		setSelectedDate(date);
		history.push("/addevent");
	};

	const handleEvent2 = (currentDate: Date, hour: string) => {
		const dateToCompare = format(currentDate, "yyyy-MM-dd");
		const eventsToDisplay = eventsList.filter(
			(event: EventInterface) => event.eventDate === dateToCompare
		);
		if (eventsToDisplay.length > 0) {
			const hourToCompare = hour.length === 1 ? `0${hour}` : hour;
			const eventsToDisplayForThisHour = eventsToDisplay.filter(
				(event: EventInterface) => event.eventTiming.split(":")[0] === hourToCompare
			);
			if (eventsToDisplayForThisHour.length > 0) {
				return eventsToDisplayForThisHour.map((event: EventInterface) => (
					<EventScheduled
						event={event}
						setShow={setShow}
						key={event.id}
						view={"week"}
						seniors={seniors}
					/>
				));
			} else {
				return canManageCalendar ? (
					<div className="add-event" onClick={() => handleAddEvent(currentDate)}>
						<BsCalendarPlus className="mr-2" /> {t("postsNavigation.__create__")}
					</div>
				) : null;
			}
		}
	};

	const generateDatesForSelectedWeek = (currentDate: Date) => {
		const week = [];
		for (let day = 0; day < 7; day++) {
			week.push(<div key={day}>{handleEvent2(currentDate, hour)}</div>);
			currentDate = addDays(currentDate, 1);
		}
		return week;
	};

	const getWeek = () => {
		const startOfTheSelectedWeek = startOfWeek(selectedDate, { weekStartsOn: 1 });
		const endOfTheSelectedWeek = endOfWeek(selectedDate, { weekStartsOn: 1 });

		let currentDate = startOfTheSelectedWeek;
		const selectedWeek = [];

		while (currentDate <= endOfTheSelectedWeek) {
			selectedWeek.push(generateDatesForSelectedWeek(currentDate));
			currentDate = addDays(currentDate, 7);
		}
		return <div className="weekContainer">{selectedWeek}</div>;
	};

	return getWeek();
};

export default CalendarWeekViewContainer;
