import React, { useState, useContext } from "react";
import http from "../services/interceptor";
import * as url from "../constants/Url";
import useDeviceDetect from "../hooks/useDeviceDetect";
import { useHistory } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import "./Login.scss";
import LostPassword from "../components/User/LostPassword";
import Header from "../layout/Header";
import Rgpd from "./Rgpd";
import { CAN_POST_MODIFY_HOME_BLOG, HOME_BLOG_WRITER } from "../constants/Permissions";
import { FaEye, FaTimes } from "react-icons/fa";
import HandleError from "../layout/HandleError";

function Login() {
	const isMobile = useDeviceDetect();
	const history = useHistory();
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
	const [viewPassword, setViewPassword] = useState(false);
	const [loginError, setLoginError] = useState(false);
	const [lostPassword, setLostPassword] = useState(false);
	const [show, setShow] = useState(false);
	const [permissionDenied, setPermissionDenied] = useState(false);

	const RgpdModal = ({ show, setShow }) => {
		const content = show && (
			<div className="overlay">
				<div className="modal-rgpd">
					<p className="btn btn-sm btn-outline-secondary right" onClick={() => setShow(false)}>
						<FaTimes />
					</p>
					<Rgpd show={show} setShow={setShow} />
				</div>
			</div>
		);
		return content;
	};

	const { userDispatch } = useContext(UserContext);
	const { t } = useTranslation("common");

	const handleSubmit = e => {
		e.preventDefault();
		setLoginError(false);
		setPermissionDenied(false);
		const loginData = {
			user: {
				login: login,
				password: password
			}
		};

		http
			.post(`${url.BASE_URL}/login`, loginData)
			.then(res => {
				if (res.data.user.home_id) {
					userDispatch({
						type: "LOGIN",
						payload: res.data.user
					});
					sessionStorage.setItem("isLogged", true);
					localStorage.setItem("firstName", JSON.stringify(res.data.user.first_name));
					localStorage.setItem("lastName", JSON.stringify(res.data.user.last_name));
					localStorage.setItem("type", JSON.stringify(res.data.user.type_id));
					localStorage.setItem("permissions", JSON.stringify(res.data.user.permissions));
					localStorage.setItem("homeId", JSON.stringify(res.data.user.home_id));
					localStorage.setItem("id", JSON.stringify(res.data.user.id));
					localStorage.setItem("locale", JSON.stringify(res.data.user.locale));

					if (res.data.user.locale === "fr") {
						localStorage.setItem("i18next", "fr-FR");
					} else if (res.data.user.locale === "nl") {
						localStorage.setItem("i18next", "nl-BE");
					} else {
						localStorage.setItem("i18next", "en-EN");
					}

					if (
						res.data.user.permissions.includes(HOME_BLOG_WRITER) ||
						res.data.user.permissions.includes(CAN_POST_MODIFY_HOME_BLOG)
					) {
						setPermissionDenied(false);
						if (res.data.user.has_accepted_rgpd) {
							window.scrollTo({
								top: 0,
								left: 0,
								behavior: "smooth"
							});
							history.push("/blog/addpost");
						} else {
							setShow(true);
						}
					} else {
						setPermissionDenied(true);
					}
				} else {
					setPermissionDenied(true);
				}
			})
			.catch(() => {
				setLoginError(true);
			});
	};

	return (
		<div className="main-layout">
			<Header userState={null} />
			<div className="layout">
				{lostPassword ? (
					<LostPassword displayTitle={true} />
				) : (
					<div className={isMobile.isMobile ? "mx-auto" : "login-container mx-auto"}>
						<h1 className="center mb-5">{t("login.__connexion__")}</h1>
						{loginError ? (
							<HandleError message={t("login.__loginError__")} type="error" setError={setLoginError} />
						) : null}
						{permissionDenied ? (
							<p className="error">
								Vous n'avez pas l'autorisation d'accéder à cet espace. Veuillez contacter votre
								administrateur.
							</p>
						) : null}
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="login">{t("common.__login__")}</label>
								<input
									type="text"
									id="login"
									className="form-control"
									onChange={e => setLogin(e.target.value)}
									value={login}
									required
								/>
								<small id="loginHelp" className="form-text text-muted right">
									{t("common.__required__")}
								</small>
							</div>
							<div className="form-group input-password mt-5">
								<label htmlFor="password">{t("login.__password__")}</label>
								<input
									type={viewPassword ? "text" : "password"}
									id="password"
									className="form-control"
									onChange={e => setPassword(e.target.value)}
									value={password}
									required
								/>
								<FaEye
									role="button"
									className="view-password fake-link"
									onClick={() => setViewPassword(prevViewPassword => !prevViewPassword)}
								/>
								<small id="passwordHelp" className="form-text text-muted right">
									{t("common.__required__")}
								</small>
							</div>
							<div className="mb-4 mt-5 center">
								<p className="blue fake-link" onClick={() => setLostPassword(true)}>
									{t("login.__lostPassword__")}
								</p>
								<button type="submit" className="btn btn-primary btn-lg">
									{t("login.__connexion__")}
								</button>
							</div>
						</form>
					</div>
				)}
			</div>
			<RgpdModal show={show} setShow={setShow} />
		</div>
	);
}

export default Login;
