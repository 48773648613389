import React, { useEffect } from "react";
import { BsPersonCircle, BsCalendar3, BsMegaphone } from "react-icons/bs";
import { Link } from "react-router-dom";
import { UserInterface, SeniorInterface } from "../interfaces/UserStateInterface";
import http from "../services/interceptor";
import * as url from "../constants/Url";

interface Props {
	userState: UserInterface | null;
	userDispatch?: any;
}

export default function Header({ userState, userDispatch }: Props) {
	useEffect(() => {
		if (userState && userState.userConnected.home_id && userDispatch) {
			if (userState.seniors.length === 0) {
				http.get(`${url.HOMES_URL}/${userState.userConnected.home_id}/seniors?room=true`).then(res => {
					let response = res.data.seniors;
					let seniors = response.map(function (senior: SeniorInterface) {
						return {
							...senior,
							value: senior.id,
							label: `${senior.last_name.toUpperCase()} ${senior.first_name}`,
							name: `${senior.last_name.toUpperCase()} ${senior.first_name}`,
							room: senior.room ? senior.room.name : null,
							homeUnitId: senior.room ? senior.room.home_unit_id : null
						};
					});
					userDispatch({
						type: "GET_SENIORS",
						payload: seniors
					});
				});
			}
			if (userState.homeUnits.length === 0) {
				http
					.get(`${url.HOMES_URL}/${userState.userConnected.home_id}/home_units`)
					.then(res => userDispatch({ type: "GET_HOME_UNITS", payload: res.data.home_units }));
			}
		}
	}, [userState]);

	return userState && userState.isLogged ? (
		<header>
			<Link to="/blog/addpost">
				{process.env.REACT_APP_EFORLINK_LOGO === "true" ? (
					<img src="/img/white-logo-e4link.png" alt="logo e4link" className="logo" />
				) : (
					<img src="/img/amd/amdlink-white.png" alt="logo amd connect" className="amd-logo" />
				)}
			</Link>
			<Link to="/myaccount">
				<BsPersonCircle className="user-icon" />
			</Link>
			<Link to="/flow">
				<BsMegaphone className="user-icon" />
			</Link>
			<Link to="/calenday">
				<BsCalendar3 className="user-icon" />
			</Link>
		</header>
	) : (
		<header>
			<Link to="/">
				{process.env.REACT_APP_EFORLINK_LOGO === "true" ? (
					<img src="/img/white-logo-e4link.png" alt="logo e4link" className="logo" />
				) : (
					<img src="/img/amd/amdlink-white.png" alt="logo amd connect" className="amd-logo" />
				)}
			</Link>
		</header>
	);
}
