import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { RemoveEventSuccessMessage } from "../components/Calendar/RemoveEvent";
import { UserInterface } from "../interfaces/UserStateInterface";
import { EventInterface } from "../interfaces/EventInterface";
import CalendarDayView from "../components/Calendar/CalendarDayView";
import CalendarHeader from "../layout/CalendarHeader";
import HandleSuccess from "../layout/HandleSuccess";
import HandleLocale from "../utils/HandleLocale";
import http from "../services/interceptor";
import * as p from "../constants/Permissions";
import * as url from "../constants/Url";

interface Props {
	userState: UserInterface;
	selectedDate: Date;
	setSelectedDate: Function;
}

const Calenday = ({ userState, selectedDate, setSelectedDate }: Props) => {
	const seniors = userState.seniors;
	const homeId = userState.userConnected.home_id;
	const [eventsList, setEventsList] = useState([]);
	const locale = HandleLocale(userState.userConnected.locale);
	const canManageCalendar = userState.userConnected.is(p.CAN_MANAGE_HOME_CALENDAR);
	const location: any = useLocation();
	const displaySuccessMessage = location.state?.success ? true : false;
	const [success, setSuccess] = useState(displaySuccessMessage);
	const [show, setShow] = useState({ show: false, event: null, success: false });

	useEffect(() => {
		if (homeId && selectedDate) {
			const startDate = format(selectedDate, "yyyy-MM-dd'T'00:00:00");
			const endDate = format(selectedDate, "yyyy-MM-dd'T'23:59:59");

			http
				.get(
					`${url.HOMES_URL}/${homeId}/home_calendar_entries/from/${encodeURIComponent(
						startDate
					)}/to/${encodeURIComponent(endDate)}`
				)
				.then(res => {
					const result = res.data.calendar_entries;
					const events = result.map((event: EventInterface) => {
						return {
							...event,
							eventDate: event.begin_datetime.split(" ")[0],
							eventTiming:
								event.begin_datetime.split(" ")[1].split(":")[0] +
								":" +
								event.begin_datetime.split(" ")[1].split(":")[1]
						};
					});
					const orderedEvents = events.sort((a: EventInterface, b: EventInterface) =>
						a.begin_datetime.localeCompare(b.begin_datetime)
					);
					setEventsList(orderedEvents);
				})
				.catch(() => setEventsList([]));
		}
		// eslint-disable-next-line
	}, [selectedDate, homeId, show.success]);

	return (
		<>
			<CalendarHeader
				selectedDate={selectedDate}
				setSelectedDate={setSelectedDate}
				view={"day"}
				locale={locale}
			/>
			{success ? <HandleSuccess type="success" setSuccess={setSuccess} /> : null}
			{show.success ? <RemoveEventSuccessMessage setShow={setShow} /> : null}
			<CalendarDayView
				userState={userState}
				eventsList={eventsList}
				selectedDate={selectedDate}
				setSelectedDate={setSelectedDate}
				canManageCalendar={canManageCalendar}
				seniors={seniors}
				show={show}
				setShow={setShow}
			/>
		</>
	);
};

export default Calenday;
