import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaRegHeart, FaHeart, FaRegClock, FaRegCommentAlt, FaUsersCog } from "react-icons/fa";
import { BsMegaphone } from "react-icons/bs";
import { displayMeta } from "../BlogUtils";
import { UserInterface, SeniorInterface } from "../../../interfaces/UserStateInterface";
import { PostInterface } from "../../../interfaces/BlogInterface";
import format from "date-fns/format";
import WhoLikeModal from "../WhoLikeModal";
import Parse from "html-react-parser";
import DisplayMedias from "../../../utils/DisplayMedias";
import Comments from "../Comments/Comments";
import "./News.scss";

interface Props {
	isBloggerOrModerator: boolean;
	seniors: SeniorInterface[];
	displayStatus: boolean;
	post: PostInterface;
	userState: UserInterface;
	blogDispatch: Function;
}

export default function PostContainer({
	isBloggerOrModerator,
	post,
	seniors,
	userState,
	blogDispatch
}: Props) {
	const { t } = useTranslation("common");
	const [show, setShow] = useState<{ show: boolean; postId: number | null }>({
		show: false,
		postId: null
	});
	const [showComments, setShowComments] = useState({ show: false, postId: null, counter: 0 });
	const [displaySeniors, setDisplaySeniors] = useState<{ display: boolean; id: number | null }>({
		display: false,
		id: null
	});

	const canEditPost = (postId: number) => {
		if (isBloggerOrModerator) {
			return (
				<Link to={`/news/editpost/${postId}`}>
					<button type="button" className="btn btn-outline-primary mr-3 mt-3 float-right btn-sm">
						{t("common.__update__")}
					</button>
				</Link>
			);
		}
	};

	const handleShowComments = (post: any) => {
		if (showComments.show) {
			if (showComments.postId === post.id) {
				setShowComments({ show: false, postId: null, counter: 0 });
			} else {
				setShowComments({ show: true, postId: post.id, counter: post.comment_counter });
			}
		} else {
			setShowComments({ show: true, postId: post.id, counter: post.comment_counter });
		}
	};

	return (
		<>
			{post.accepted === null ? canEditPost(post.id) : null}
			<div className="d-flex justify-content-between occupation-flow">
				<Link to={{ pathname: `/post/${post.id}`, state: { post: post } }}>
					<p className="occupation-title">
						<BsMegaphone className="mr-2" /> {post.title}
					</p>
				</Link>
				{post.focused_on ? (
					<div
						className="occupation-toggle"
						role="button"
						onClick={() => setDisplaySeniors({ display: !displaySeniors.display, id: post.id })}>
						<FaUsersCog className="nav-icon mr-2" />
						{post.seniors_focused_on.length}
					</div>
				) : null}
			</div>
			<div className="occupation-block mb-1">
				<span className="occupation-meta mr-3">
					<FaRegClock className="nav-icon mr-2" />
					{format(new Date(post.date.split(" ")[0]), "dd/MM/yyyy")}
				</span>
				{displayMeta(post.medias)}

				{post.focused_on && displaySeniors.display && displaySeniors.id === post.id ? (
					<div className="mt-3 mb-3">
						{post.seniors_focused_on.map(
							(senior: { id: number; first_name: string; last_name: string }) => (
								<span key={senior.id} className="senior-badge mr-2 mt-1">
									{senior.last_name.toUpperCase()} {senior.first_name}
								</span>
							)
						)}
					</div>
				) : null}
			</div>
			{post.medias.length > 0 ? (
				<div className="media-box">
					<DisplayMedias medias={post.medias} />{" "}
				</div>
			) : null}
			<div className="social-text">{Parse(post.body)}</div>
			<div className="social-separator"></div>
			<div className="center social-like row">
				<div className="col-6">
					{post.like_counter > 0 ? (
						<span
							className="like-button"
							role="button"
							onClick={() => setShow({ show: true, postId: post.id })}>
							<FaHeart className="social-icon mr-2" />
							<span>
								<b>{post.like_counter}</b>
							</span>
						</span>
					) : (
						<span className="meta">
							<FaRegHeart className="social-icon darkgrey mr-2" /> 0
						</span>
					)}
				</div>
				<div className="col-6">
					{post.comment_counter > 0 ? (
						<span className="fake-link" role="button" onClick={() => handleShowComments(post)}>
							<b>
								<FaRegCommentAlt className="social-icon blue mr-2" />
								{post.comment_counter}
							</b>
						</span>
					) : post.accepted ? (
						<span className="fake-link meta" onClick={() => handleShowComments(post)}>
							<FaRegCommentAlt className="social-icon darkgrey mr-2" /> 0
						</span>
					) : (
						<>
							<FaRegCommentAlt className="social-icon darkgrey mr-2" /> 0
						</>
					)}
				</div>
			</div>
			{showComments.show && post.id === showComments.postId ? (
				<>
					<div className="social-separator"></div>
					<div style={{ margin: "1rem" }}>
						<Comments
							postId={post.id}
							commentsCounter={post.comment_counter}
							userState={userState}
							blogDispatch={blogDispatch}
						/>
					</div>
				</>
			) : null}

			<WhoLikeModal seniors={seniors} show={show} setShow={setShow} />
		</>
	);
}
