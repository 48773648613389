import i18n from "../i18n/i18next";

export const fileSize = size => {
	if (size === 0) return "0 Bytes";
	const k = 1024;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
	const i = Math.floor(Math.log(size) / Math.log(k));
	return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const onUploadFiles = (e, medias, setMedias, uploadSize, setUploadSize) => {
	if (e.target.files && e.target.files.length > 0) {
		let newFiles = [...e.target.files];
		const allFiles = [...medias, ...newFiles];
		allFiles.map(file => {
			return (file["preview"] = URL.createObjectURL(file));
		});
		setMedias(allFiles);
		const newFilesSizes = newFiles.map(file => file.size);
		const newUploadSize = uploadSize + newFilesSizes.reduce((a, b) => a + b, 0);
		setUploadSize(newUploadSize);
	}
};

export const onUploadVideo = (e, medias, setMedias, uploadSize, setUploadSize) => {
	if (e.target.files && e.target.files.length > 0) {
		setMedias([...medias, e.target.files[0]]);
		const videoUploadedSize = e.target.files[0].size;
		setUploadSize(videoUploadedSize + uploadSize);
	}
};

export const postState = accepted => {
	switch (accepted) {
		case false:
			return i18n.t("common:news.__moderationRefused__");
		case true:
			return i18n.t("common:news.__moderationOk__");
		default:
			return i18n.t("common:news.__moderationPending__");
	}
};
