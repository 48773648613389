import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsFillPersonDashFill, BsFillPersonPlusFill } from "react-icons/bs";
import { HomeUnitsInterface, SeniorInterface } from "../../interfaces/UserStateInterface";

interface SelectionsSelectionProps {
	seniors: SeniorInterface[];
	seniorsSelected: number[];
	setSeniorsSelected: Function;
	homeUnits: HomeUnitsInterface[];
}

export default function SeniorsSelectionContainer({
	seniors,
	seniorsSelected,
	setSeniorsSelected,
	homeUnits
}: SelectionsSelectionProps) {
	const { t } = useTranslation("common");
	const [selectAllSeniors, setSelectAllSeniors] = useState(false);
	const [homeUnitSelected, setHomeUnitSelected] = useState(0);
	const [seniorsToDisplay, setSeniorsToDisplay] = useState(seniors);

	const handleSeniors = (id: number, selectionType: string) => {
		if (selectionType === "ALL") {
			if (selectAllSeniors) {
				setSelectAllSeniors(false);
				setSeniorsSelected([]);
			} else {
				setSelectAllSeniors(true);
				const allSeniors = seniors.map((senior: SeniorInterface) => senior.id);
				setSeniorsSelected(allSeniors);
			}
		} else if (selectionType === "HOMEUNIT") {
			setHomeUnitSelected(id);
			const seniorsFormHomeUnit = seniors.filter(senior => senior.homeUnitId === id);
			setSeniorsToDisplay(seniorsFormHomeUnit);
		} else {
			if (seniorsSelected.includes(id)) {
				setSeniorsSelected(seniorsSelected.filter((senior: number) => senior !== id));
			} else {
				setSeniorsSelected([...seniorsSelected, id]);
			}
		}
	};

	const displaySeniors = (search: string) => {
		const filteredSeniors = seniors.filter(senior => {
			return senior.name.toLowerCase().includes(search.toLowerCase());
		});
		setSeniorsToDisplay(filteredSeniors);
	};

	return (
		<div className="mt-4">
			<p>{t("news.__selectSenior__")} : </p>
			<input
				type="text"
				className="form-control mb-3"
				onChange={e => displaySeniors(e.target.value)}
				placeholder={t("common.__search__")}
			/>
			{homeUnits.length > 0 ? (
				<ul className="senior-buttons mt-3">
					{homeUnits.map(homeUnit => (
						<li
							key={homeUnit.id}
							className={homeUnitSelected === homeUnit.id ? "home-unit-button" : "all-seniors-button"}
							onClick={() => handleSeniors(homeUnit.id, "HOMEUNIT")}
							role="button">
							{homeUnit.name}
						</li>
					))}
				</ul>
			) : (
				<div
					className={selectAllSeniors ? "all-seniors-button" : "home-unit-button"}
					onClick={() => handleSeniors(0, "ALL")}
					role="button">
					{selectAllSeniors ? (
						<BsFillPersonDashFill className="senior-buttons-icon" role="button" />
					) : (
						<BsFillPersonPlusFill className="senior-buttons-icon" role="button" />
					)}
					{t("calendar.__selectSeniors__")}
				</div>
			)}
			<ul className="senior-buttons">
				{seniorsToDisplay.map(senior => (
					<li
						key={senior.id}
						className={seniorsSelected.includes(senior.id) ? "senior-buttons-del" : "senior-buttons-add"}
						onClick={() => handleSeniors(senior.id, "SINGLE")}
						role="button">
						{seniorsSelected.includes(senior.id) ? (
							<BsFillPersonDashFill className="senior-buttons-icon" role="button" />
						) : (
							<BsFillPersonPlusFill className="senior-buttons-icon" role="button" />
						)}
						{senior.name}
					</li>
				))}
			</ul>
		</div>
	);
}
