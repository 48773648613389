import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

interface Props {
	name: string;
	label: string;
	initialValue: string;
	behavior: { required: boolean };
}

export default function MyInputText({ name, label, initialValue, behavior }: Props) {
	const { t } = useTranslation("common");
	const {
		register,
		formState: { errors }
	} = useFormContext<any>();
	return (
		<div className="form-group">
			<label htmlFor={name} className="form-label mt-4">
				{label}
			</label>
			<input
				id={name}
				defaultValue={initialValue}
				{...register(name, { required: behavior.required })}
				className="form-control form-control-lg"
			/>
			{behavior.required ? (
				<small className="form-text text-muted right" id={`${name}Error`}>
					{errors[name] ? (
						<p className="red">
							<b>{t("common.__inputRequired__")}</b>
						</p>
					) : (
						<span className="required">{t("common.__required__")}</span>
					)}
				</small>
			) : null}
		</div>
	);
}
